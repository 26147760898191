import { useTranslation } from "react-i18next";
import { BOX_STATUS_CHECK, BOX_STATUS_UNLOCK } from "../../constance";

function InfoContent(props) {
  const { type, status, onOpen, name, isLoading } = props;
  const { t } = useTranslation();
  console.log(name)

  const btnTxtDic = {};

  const onClick = (e) => {
    e?.stopPropagation();
  };

  return (
    <div className="w-full h-[36px] flex mb-[10px] pb-[15px]" onClick={onClick}>
      <div className="w-full flex items-center relative pr-[10px]">
        <div className="w-[64px] h-[64px] absolute">
          {!isLoading && (
            <img
              src={`game_main_box_${type}.png`}
              className="absolute w-[64px] h-[64px]"
            />
          )}
          {status === "done" && (
            <img src="box-checked.svg" className="absolute  z-20" />
          )}
        </div>
        <div className="w-4/5 ml-[15px] flex items-center pl-[60px] text-white bg-[#475559] text-[15px] font-semibold h-[36px]">
          <div>{t(`pika.${name}`)}</div>
        </div>
        <div
          className={`w-[78px] bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-black h-[36px] rounded-r-[8px] flex items-center justify-center text-[15px] font-semibold brightness-100 hover:brightness-75  active:brightness-60 ${
            status === BOX_STATUS_CHECK || status === BOX_STATUS_UNLOCK
              ? "cursor-pointer"
              : ""
          }`}
          onClick={onOpen}
        >
          {status === "done" ? t("pika.checked") : t(`pika.${status || "unlock"}`)}
        </div>
      </div>
    </div>
  );
}

export default InfoContent;
