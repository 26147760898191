function PointInfo(props) {
  const { value } = props;
  return (
    <div className="w-full">
      <div
        style={{
          background: "linear-gradient(90deg, #00FFFF 0%, #FFF6C5 100%)",
        }}
        className="h-[19px] rounded-[17px] w-[100px] text-[12px] flex items-center justify-center ml-[8px] mt-[8px]"
      >
        <img
          alt="score"
          loading="lazy"
          width="13"
          height="13"
          decoding="async"
          data-nimg="1"
          srcSet="/score-white.svg 1x, /score-white.svg 2x"
          src="/score-white.svg"
          style={{ color: "transparent", marginRight: "5px" }}
        />
        {`+${value} $Pika`}
      </div>
    </div>
  );
}
export default PointInfo;
