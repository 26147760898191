import { httpPost } from "../../utils/httpRequest";

/**
 * 游客登录
 * @returns
 * @see https://candy-porpoise-601.notion.site/ef9530344ccc4bf2a1b12ad2a871efca
 */
export async function queryVisitorLogin(data) {
  const reqData = await httpPost("/v1/users/visitorLogin", data);
  return reqData;
}

/**
 * 检查任务完成
 * @returns
 * @see https://candy-porpoise-601.notion.site/ef9530344ccc4bf2a1b12ad2a871efca
 */
export async function checkStep(data) {
  const reqData = await httpPost("/v1/users/checkStep", data);
  return reqData;
}

/**
 * 埋点记录
 * @returns
 * @see https://candy-porpoise-601.notion.site/30a4802d77fb426198ff8b8318d9a028?pvs=4
 */
export async function seedStep(data) {
  const reqData = await httpPost("/v1/users/clickStep", data);
  return reqData;
}
