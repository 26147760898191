import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function HistoryItem({ from, num, point, symbol, time }) {
  const { t } = useTranslation();
  const ways = {
    "newUser": t("pika.by Invite friends"),
    "motherUser": t("pika.by Invited"),
    "finshTask": t("pika.by task"),
    "Task": t("pika.by task"),
    "ploughing": t("pika.by Farm and Claim"),
    "tapBox": t("pika.by Tap Box"),
    "checkBox": t("pika.by Check Box"),
    "taskRankAward": t("pika.by Rank reward"),
    "admin": t("pika.by playandearn"),
    "dailyBouns": t("pika.by Daily Bonus"),
    "SurvivorLevelRank": t("pika.by Survivor Level Rank")
  }

  return (
    <li className="flex items-start py-[15px] px-2 border-b border-[#FFFFFF1A]">
      <div className="pl-2 pr-3 pt-2.5">
        <span
          className="block w-[3px] h-[3px] bg-white -rotate-45 "
          style={{
            boxShadow: "0px 0px 0px 3px rgba(255, 255, 255, 0.2)"
          }}
        ></span>
      </div>
      <div className="text-[#FFFFFFCC] text-sm font-medium">
        <div>{dayjs(time * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
        <div className="flex items-baseline">
          <span className="text-[#17FFF7] font-bold text-lg mr-2">{ point >=0 ? `+${point}`: point }</span>
          <span className="text-white mr-1">{symbol || "Pika"}</span>
          {from === "newUser" ? `${ways[from]} ${num}per` : ways[from] ? ways[from] : from}
        </div>
      </div>
    </li>
  )
}