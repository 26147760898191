export default function RightArrow() {
  return (
    <svg
      width='8'
      height='9'
      viewBox='0 0 8 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='path-1-inside-1_849_1347' fill='white'>
        <path d='M4.46436 0.964478L7.99989 4.50001L4.46436 8.03555L0.928822 4.50001L4.46436 0.964478Z' />
      </mask>
      <path
        d='M7.99989 4.50001L8.707 5.20712L9.4141 4.50001L8.707 3.7929L7.99989 4.50001ZM3.75725 1.67158L7.29278 5.20712L8.707 3.7929L5.17146 0.257371L3.75725 1.67158ZM7.29278 3.7929L3.75725 7.32844L5.17146 8.74265L8.707 5.20712L7.29278 3.7929Z'
        fill='#8BFFF8'
        mask='url(#path-1-inside-1_849_1347)'
      />
      <path
        d='M7.99989 4.50001L8.707 5.20712L9.4141 4.50001L8.707 3.7929L7.99989 4.50001ZM3.75725 1.67158L7.29278 5.20712L8.707 3.7929L5.17146 0.257371L3.75725 1.67158ZM7.29278 3.7929L3.75725 7.32844L5.17146 8.74265L8.707 5.20712L7.29278 3.7929Z'
        fill='white'
        mask='url(#path-1-inside-1_849_1347)'
      />
    </svg>
  )
}
