import CountUp from "react-countup"
import { Tooltip } from "react-tippy";
import PointSvg from "../../../components/svg/PointSvg"
import QuestionMarkSvg from "../../../components/svg/QuestionMarkSvg";
import RightArrowSvg from "../../../components/svg/RightArrowSvg";
import { useTranslation } from "react-i18next";

function PikaDesc() {
  const { t } = useTranslation();

  return (
    <div className="text-left" style={{width: 300}}>
      <h4 className="font-medium mb-2">${t("pika.About $PIKA")}</h4>
      <p>
        ※ {t("pika.$PlKA_desc1")}
      </p>
      <p>
        ※ {t("pika.$PlKA_desc2")}
      </p>
      <p>※ {t("pika.$PlKA_desc3")}</p>
    </div>
  )
}

function CFUNDesc() {
  const { t } = useTranslation();
  return (
    <div className="text-left" style={{width: 300}}>
      <h4 className="font-medium mb-2">{t("pika.$CFUN in the Pika")}</h4>
      <p>
        ※ {t("pika.$CFUN _desc")}
      </p>
    </div>
  )
}

function UserInfo({ $CFUN, $Pika }) {
  const { t } = useTranslation();
  return (
    // <div
    //   className="mt-3 flex rounded-lg border-2 border-[#FFFFFF4D] p-[8px]"
    //   style={{
    //     background:
    //       "linear-gradient(277.76deg, rgb(239, 239, 255) 1.35%, rgba(239, 239, 255, 0.1) 99%)",
    //   }}
    // >
    //   <div className="flex-1">
    //     <div
    //       className="justify-center px-2 text-[20px] font-bold"
    //       style={{
    //         background:
    //           "linear-gradient(102.23deg, rgb(101, 190, 255) -28.9%, rgb(118, 111, 250) 86.85%) text",
    //         Webkit: "transparent",
    //       }}
    //     >
    //       <div className="flex justify-center">670</div>
    //     </div>
    //     <div
    //       className="justify-center px-2 text-[14px] text-[#180940]"
    //       style={{ fontWeight: "400" }}
    //     >
    //       <div className="flex justify-center">$CPOINT</div>
    //     </div>
    //   </div>
    //   <hr className="mx-3 my-2 h-auto border-1 border-[#0000001A]" />
    //   <div className="flex-1">
    //     <div
    //       className="justify-center px-2 text-[20px] font-bold"
    //       style={{
    //         background:
    //           "linear-gradient(102.23deg, rgb(101, 190, 255) -28.9%, rgb(118, 111, 250) 86.85%) text",
    //         Webkit: "transparent",
    //       }}
    //     >
    //       <div className="flex justify-center">170</div>
    //     </div>
    //     <div
    //       className="justify-center px-2 text-[14px] text-[#180940]"
    //       style={{ fontWeight: "400" }}
    //     >
    //       <div className="flex justify-center">$Boost</div>
    //     </div>
    //   </div>
    //   <hr className="mx-3 my-2 h-auto border-1 border-[#0000001A]" />
    //   <div className="flex-1">
    //     <div
    //       className="justify-center px-2 text-[20px] font-bold"
    //       style={{
    //         background:
    //           "linear-gradient(102.23deg, rgb(101, 190, 255) -28.9%, rgb(118, 111, 250) 86.85%) text",
    //         Webkit: "transparent",
    //       }}
    //     >
    //       <div className="flex justify-center">0</div>
    //     </div>
    //     <div
    //       className="justify-center px-2 text-[14px] text-[#180940]"
    //       style={{ fontWeight: "400" }}
    //     >
    //       <div className="flex justify-center">Badges</div>
    //     </div>
    //   </div>
    // </div>
    <div
      className="mt-5 p-5 text-white rounded-xl flex items-start justify-around w-full"
      style={{
        background: `linear-gradient(0deg, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)), linear-gradient(244.58deg, rgba(0, 42, 51, 0.8) 17.78%, rgba(4, 37, 44, 0.8) 82.22%)`
      }}
    >
      <div className="flex flex-col items-center space-y-1">
        <span
          className="text-[rgba(255, 255, 255, 0.7)] font-[700]text-center text-xl font-[700] leading-[2rem] bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] bg-clip-text text-transparent"
        >
          {/* <CountUp start={0} end={$CFUN} duration={1.5} /> */}
          {$CFUN || 0}
        </span>
        <div className="cursor-pointer flex items-center space-x-1">
          <img
            width={16}
            height={16}
            loading="lazy"
            srcSet="/i-CFUN.png 1x, /i-CFUN.png 2x"
            src="/i-CFUN.png"
            alt="CFUN"
          />
          <span className="pr-1 text-sm">$CFUN</span>
          <Tooltip
            trigger="mouseenter"
            touchHold
            html={<CFUNDesc />}
            arrow={true}
            animation="scale"
            position="bottom"
          >
            <QuestionMarkSvg />
          </Tooltip>
        </div>
        <a
          href="https://crystalfun.medium.com/crystal-fun-tokenomic-overview-outer-gold-farming-mode-30478d8762b2"
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1 text-[11px] text-slate-300 hover:underline underline-offset-2"
        >
          <span>{t("pika.Learn more about $CFUN")}</span>
          <RightArrowSvg/>
        </a>
      </div>
      <div className="h-[80px] w-px bg-[#FFFFFF33]"></div>
      <div className="flex flex-col items-center space-y-1">
        <span
          className="text-[rgba(255, 255, 255, 0.7)] font-[700]text-center text-xl font-[700] leading-[2rem] bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] bg-clip-text text-transparent"
        >
          {/* <CountUp start={0} end={$Pika?.indexOf("k") > -1 ? $Pika?.slice(0, -1)*1000 : $Pika} duration={1.5} /> */}
          {$Pika || 0}
        </span>
        <div className="cursor-pointer flex items-center space-x-1">
          <img
            width={16}
            height={16}
            loading="lazy"
            srcSet="/icon-c-color.png 1x, /icon-c-color.png 2x"
            src="/icon-c-color.png"
            alt="PIKA"
          />
          <span className="pr-1 text-sm">$Pika</span>
          <Tooltip
            trigger="mouseenter"
            touchHold
            html={<PikaDesc />}
            arrow={true}
            animation="scale"
            position="bottom"
          >
            <QuestionMarkSvg />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
