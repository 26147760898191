import { useEffect, useState } from "react";
import { sortBy } from "lodash";
import TabBar from "./components/TabBar";
import TaskList from "./components/TaskList";
import RankList from "./components/RankList";
import { queryRankInfo, queryTaskInfos } from "../../api/task";
import CPlayTasksTemplates from "../../constants/CPlayTasksTemplates";
import { useJumpChain } from "../../hooks/useJumpChain";
import { showToast } from "../../components/showToast";
import { COMMON_TOAST_TYPE_WARNNING } from "../../components/showToast/constance";
import { useTranslation } from "react-i18next";

export default function TaskPage() {
  const { t } = useTranslation();
  const [curTab, setCurTab] = useState("task");
  const [taskInfo, setTaskInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [rankInfo, setRankInfo] = useState({});

  useJumpChain({});

  const onTabChange = (key) => {
    setCurTab(key);
    if (key === "task") {
      getTaskInfo();
    } else {
      getRankList();
    }
  };

  const getRankList = async () => {
    try {
      setLoading(true);
      const params = {
        pageIndex: 0,
        pageSize: 200,
        key: "pika",
      };
      const resp = await queryRankInfo(params);
      setRankInfo(resp);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(t(`api.${error.message}`), COMMON_TOAST_TYPE_WARNNING)
    }
  };

  const getTaskInfo = async () => {
    try {
      setLoading(true);
      const resp = await queryTaskInfos();
      const taskList = Object.keys(resp).map((k) => {
        let taskCfg = CPlayTasksTemplates[k];
        const { condProgress, state } = resp[k];
        const obj = { ...taskCfg, condProgress, state };
        return obj;
      });
      const tasksBySort = sortBy(taskList, o => o.sort);
      setTaskInfo(sortBy(tasksBySort, (o) => o.state));
      setLoading(false);
    } catch (error) {
      console.log(error);
      showToast(t(`api.${error.message}`), COMMON_TOAST_TYPE_WARNNING)
      setLoading(false);
    }
  };

  useEffect(() => {
    getTaskInfo();
  }, []);

  function onChange(id, item) {
    getTaskInfo();
  }

  return (
    <div className="px-4 py-4 select-none min-h-screen overflow-hidden flex flex-col">
      <TabBar tab={curTab} onClick={onTabChange} />
      <div className="flex flex-1 overflow-auto h-full w-full">
        {curTab === "task" && (
          <TaskList data={taskInfo} loading={loading} onChange={onChange} />
        )}
        {curTab === "rank" && <RankList loading={loading} {...rankInfo} />}
      </div>
    </div>
  );
}
