import { Bounce } from "react-toastify";

const SUCCESS_ICON =
  "https://assets.outer.gg/frontend/img/common/i-success.svg";
const WARNNING_ICON =
  "https://assets.outer.gg/frontend/img/common/i-warning.svg";

export const COMMON_TOAST_TYPE_SUCCESS = "1";
export const COMMON_TOAST_TYPE_WARNNING = "2";

export const dicCfg = {
  [COMMON_TOAST_TYPE_SUCCESS]: {
    title: "Success!",
    icon: SUCCESS_ICON,
    style: "success",
  },
  [COMMON_TOAST_TYPE_WARNNING]: {
    title: "Warning!",
    icon: WARNNING_ICON,
    style: "warning",
  },
};

export const toastCfg = {
  position: "top-right",
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  transition: Bounce,
};
