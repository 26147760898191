import { useEffect, useState } from "react";
import PikaBot from "./components/PikaBot";
import Verify from "./components/Verify";
import { useDispatch, useSelector } from "react-redux";
import { setCheckState, setVerifyPage } from "../../store/features/userSlice";

export default function Club() {
  const isVerifyPage = useSelector(state => state.user.isVerifyPage);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setVerifyPage(true));
  }

  useEffect(() => {
    return () => {
      dispatch(setVerifyPage(false));
      dispatch(setCheckState(false));
    }
  }, [])

  return (
    <div className="h-screen overflow-hidden pt-8 pb-28">
      {isVerifyPage ? <Verify /> : <PikaBot onClick={onClick} />}
    </div>
  )
}