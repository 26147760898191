import AssetsIcon from "./AssetsIcon";
import { thousandEnNum } from "../../../../utils";

function IconBar(props) {
  const { data } = props || {};
  const { ton, usd, usdt, star } = data || {};
  return (
    <div className="flex items-center justify-between px-[10px]">
      <AssetsIcon icon="/ton_icon.png" value={thousandEnNum(ton)} />
      <AssetsIcon icon="/ustd_icon.png" value={thousandEnNum(usdt)} />
      <AssetsIcon icon="/usd_icon.png" value={thousandEnNum(usd)} />
      <AssetsIcon icon="/star_icon.png" value={thousandEnNum(star)} />
    </div>
  );
}

export default IconBar;
