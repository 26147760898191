function IconItem(props) {
  const { icon, label } = props;

  return (
    <div className="w-[100%] h-[52px] flex items-center bg-[#000000] rounded-[15px] py-[10px] px-[15px] mb-[6px]">
      <img className="w-[32px] h-[32px] mr-[8px]" src={icon} />
      <p className="font-medium text-[12px] text-[#FFFFFF]">{label}</p>
    </div>
  );
}

export default IconItem;
