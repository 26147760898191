import { bindWallet } from "../../api/task";
import { showToast } from "../../components/showToast";
import { COMMON_TOAST_TYPE_WARNNING } from "../../components/showToast/constance";

export async function bindwalletFn(address, onChange, connectFn) {
  sessionStorage.setItem("bindingWellet", "1");
  if (address) {
    bindWallet({ tgAddress: address });
    showToast("Successfully bound TG wallet");
    onChange?.();
    sessionStorage.removeItem("bindingWellet");
  } else {
    connectFn?.();
    // showToast("Please connect the TG wallet first", COMMON_TOAST_TYPE_WARNNING);
  }
}
