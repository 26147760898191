export default function CompletedSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.7654 16.9676C13.2321 16.9676 16.8531 13.3466 16.8531 8.8799C16.8531 4.41321 13.2321 0.792236 8.7654 0.792236C4.29871 0.792236 0.677734 4.41321 0.677734 8.8799C0.677734 13.3466 4.29871 16.9676 8.7654 16.9676ZM13.4186 6.60583C13.7588 6.2693 13.7619 5.72065 13.4253 5.38038C13.0888 5.04011 12.5402 5.03708 12.1999 5.37361L6.96813 10.5479L5.33089 8.92863C4.99063 8.5921 4.44198 8.59513 4.10545 8.9354C3.76892 9.27567 3.77195 9.82432 4.11222 10.1608L6.35879 12.3827C6.6964 12.7166 7.23986 12.7166 7.57747 12.3827L13.4186 6.60583Z"
        fill="white"
      />
    </svg>
  );
}
