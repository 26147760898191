function LineInfo(props) {
  const { oreRemainAmount, oreAmountMax } = props?.data || {};

  return (
    <div id="top_text" className="flex items-center pb-1 px-4">
      <div className="h-[1px] flex-1 bg-gradient-to-l from-[#ffffff00] to-[#ffffff] rotate-180"></div>
      <div id="energy" className="mx-3 flex items-center justify-center">
        <img
          alt="tap_group"
          loading="lazy"
          width="14"
          height="14"
          decoding="async"
          data-nimg="1"
          srcSet="/tap-group.svg 1x, /tap-group.svg 2x"
          src="/tap-group.svg"
          style={{ color: "transparent" }}
        />
        <div
          className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 transition-background !duration-300 before:transition-opacity before:!duration-300 disableAnimation ml-2 rounded-lg"
          data-loaded="true"
        >
          <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-opacity motion-reduce:transition-none !duration-300">
            <div className="text-center text-[16px] font-[700] text-white">
              {Number(oreRemainAmount || 0).toFixed(2)}/
              {Number(oreAmountMax || 0).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[1px] flex-1 bg-gradient-to-r from-[#ffffff] to-[#ffffff00]"></div>
    </div>
  );
}

export default LineInfo;
