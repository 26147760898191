export default function BuyPage() {
  return (
    <div className="mx-auto select-none flex min-h-screen max-w-3xl flex-col bg-white">
      <header id="header" className="mx-auto h-full w-full flex-none">
        <div className="container mx-auto flex w-full max-w-3xl items-center justify-between pb-4 pt-5">
          <div className="flex w-full items-center justify-between md:block">
            <a href="/campaign/recjboa7rshMzmj3g" style={{ minWidth: "40px" }}>
              <img
                alt="back-arrow"
                loading="lazy"
                width="24"
                height="24"
                decoding="async"
                data-nimg="1"
                className="float-left mr-2"
                srcSet="/back-arrow.svg 1x, /back-arrow.svg 2x"
                src="/back-arrow.svg"
                style={{ color: "transparent" }}
              />
              <p className="float-left text-base font-semibold subpixel-antialiased">
                Order Summary
              </p>
            </a>
          </div>
        </div>
      </header>
      <main className="flex-1">
        <div className="container mx-auto w-full max-w-3xl">
          <div>
            <div className="clear-both mb-3 overflow-hidden">
              <div className="flex-col">
                <div className="flex items-center justify-between pb-2">
                  <div id="soldNum" className="text-xs">
                    <div>
                      🔥 <span className="font-bold text-[#766ffa]">111</span>
                      <span className="ml-1 text-[#666]">Sold</span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between pt-1">
                    <div className="flex space-x-1">
                      <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                        <span className="flex-1 text-inherit px-1 py-1 font-medium">
                          NFT Pass
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="float-left w-1/3">
                    <img
                      alt="FREE Giveaway🎁 2 x B-level ($180) &amp; 5 x C-level ($60) MATCHI Dancer NFTs! . What is **MATCHI**？


🕺MATCHI💃 is a Web3 dance fitness game containing AI, Token, and NFT elements. Players can use their mobile phone cameras to Dance to Earn anytime and anywhere. Now we are happy to inform you that you can participate in dancing mining to obtain early rewards and limited NFT.


What is **MATCHI DANCER NFT**？


Dancer NFT is a Character Pass NFT. Using NFT to play the game can get MCH rewards. MCH can be exchanged on pancakeswap. Different levels of NFT can get different rewards every day.


Tier   />>   Daily Reward    >>>     Cost


C-level   >>   8MCH    >>>     1000MCH


B-level   >>   25MCH    >>>     3000MCH


A-level   >>   50MCH    >>>     5000MCH


S-level   >>   120MCH    >>>     10000MCH


Current MCH price: 0.0583 USDT

Join TG to learn more https://t.me/matchi_games"
                      loading="lazy"
                      width="120"
                      height="120"
                      decoding="async"
                      data-nimg="1"
                      className="rounded-lg bg-cover"
                      srcSet="https://vsphvclqmktgsjojsdlb.supabase.co/storage/v1/render/image/public/product/Screen_Shot_2024-03-29_at_11.50.09_PM.png?width=128&amp;quality=75&amp;resize=contain 1x, https://vsphvclqmktgsjojsdlb.supabase.co/storage/v1/render/image/public/product/Screen_Shot_2024-03-29_at_11.50.09_PM.png?width=256&amp;quality=75&amp;resize=contain 2x"
                      src="https://vsphvclqmktgsjojsdlb.supabase.co/storage/v1/render/image/public/product/Screen_Shot_2024-03-29_at_11.50.09_PM.png?width=256&amp;quality=75&amp;resize=contain"
                      style={{ color: "transparent" }}
                    />
                  </div>
                  <div className="float-left w-2/3">
                    <div className="ml-3">
                      <p className="mb-1 font-bold">
                        30% OFF MATCHI DANCER NFT C-level
                      </p>
                      <p className="clear-both text-xs text-gray-500">
                        by Matchi
                      </p>
                      <div className="flex flex-row pt-1">
                        <div className="mr-2 font-bold">$40.8</div>
                        <div className="mt-1  text-xs line-through">$58.29</div>
                      </div>
                    </div>
                  </div>
                  <div className="clear-both"></div>
                </div>
              </div>
            </div>
            <div className="clear-both mb-2 border-b border-gray-300 py-2 undefined"></div>
            <form>
              <div className="mb-5">
                <div className="clear-both mb-5 rounded-lg bg-white">
                  <p className="float-left mb-4 p-1 font-bold">Email</p>
                  <img
                    alt="Email Address"
                    loading="lazy"
                    width="35"
                    height="35"
                    decoding="async"
                    data-nimg="1"
                    className="float-right"
                    srcSet="/order-name.png 1x, /order-name.png 2x"
                    src="/order-name.png"
                    style={{ color: "transparent" }}
                  />
                  <div className="clear-both">
                    <div>
                      <div
                        className="group flex flex-col w-full"
                        data-slot="base"
                        data-required="true"
                        data-has-elements="true"
                        data-has-label="true"
                        data-has-value="true"
                      >
                        <div
                          data-slot="input-wrapper"
                          className="relative w-full inline-flex tap-highlight-transparent shadow-sm px-3 bg-default-100 data-[hover=true]:bg-default-200 group-data-[focus=true]:bg-default-100 min-h-10 rounded-medium flex-col items-start justify-center gap-0 transition-background motion-reduce:transition-none !duration-150 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background h-14 py-2"
                          style={{ cursor: "text" }}
                        >
                          <label
                            data-slot="label"
                            className="absolute z-10 pointer-events-none origin-top-left rtl:origin-top-right subpixel-antialiased block text-foreground-500 cursor-text after:content-['*'] after:text-danger after:ml-0.5 will-change-auto !duration-200 !ease-out motion-reduce:transition-none transition-[transform,color,left,opacity] group-data-[filled-within=true]:text-default-600 group-data-[filled-within=true]:pointer-events-auto group-data-[filled-within=true]:scale-85 text-small group-data-[filled-within=true]:-translate-y-[calc(50%_+_theme(fontSize.small)/2_-_6px)] pe-2 max-w-full text-ellipsis overflow-hidden"
                            id="react-aria7722523593-:re:"
                            htmlFor="react-aria7722523593-:rd:"
                          >
                            Email
                          </label>
                          <div
                            data-slot="inner-wrapper"
                            className="inline-flex w-full items-center h-full box-border group-data-[has-label=true]:items-end pb-0.5"
                          >
                            <input
                              data-slot="input"
                              className="w-full font-normal bg-transparent !outline-none placeholder:text-foreground-500 focus-visible:outline-none data-[has-start-content=true]:ps-1.5 data-[has-end-content=true]:pe-1.5 text-small group-data-[has-value=true]:text-default-foreground"
                              aria-label="Email"
                              type="email"
                              required=""
                              aria-required="true"
                              id="react-aria7722523593-:rd:"
                              aria-labelledby="react-aria7722523593-:rd: react-aria7722523593-:re:"
                              value=""
                              title=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear-both mb-5 rounded-lg bg-white">
                  <p className="float-left mb-4 p-1 font-bold">Phone</p>
                  <img
                    alt="Phone Number"
                    loading="lazy"
                    width="35"
                    height="35"
                    decoding="async"
                    data-nimg="1"
                    className="float-right"
                    srcSet="/order-number.png 1x, /order-number.png 2x"
                    src="/order-number.png"
                    style={{ color: "transparent" }}
                  />
                  <div className="clear-both">
                    <div>
                      <div
                        className="group flex flex-col w-full"
                        data-slot="base"
                        data-filled="true"
                        data-filled-within="true"
                        data-has-elements="true"
                        data-has-label="true"
                      >
                        <div
                          data-slot="input-wrapper"
                          className="relative w-full inline-flex tap-highlight-transparent shadow-sm px-3 bg-default-100 data-[hover=true]:bg-default-200 group-data-[focus=true]:bg-default-100 min-h-10 rounded-medium flex-col items-start justify-center gap-0 transition-background motion-reduce:transition-none !duration-150 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background h-14 py-2"
                          style={{ cursor: "text" }}
                        >
                          <label
                            data-slot="label"
                            className="absolute z-10 pointer-events-none origin-top-left rtl:origin-top-right subpixel-antialiased block text-foreground-500 cursor-text will-change-auto !duration-200 !ease-out motion-reduce:transition-none transition-[transform,color,left,opacity] group-data-[filled-within=true]:text-default-600 group-data-[filled-within=true]:pointer-events-auto group-data-[filled-within=true]:scale-85 text-small group-data-[filled-within=true]:-translate-y-[calc(50%_+_theme(fontSize.small)/2_-_6px)] pe-2 max-w-full text-ellipsis overflow-hidden"
                            id="react-aria7722523593-:rj:"
                            htmlFor="react-aria7722523593-:ri:"
                          >
                            Phone Number
                          </label>
                          <div
                            data-slot="inner-wrapper"
                            className="inline-flex w-full items-center h-full box-border group-data-[has-label=true]:items-end pb-0.5"
                          >
                            +
                            <input
                              data-slot="input"
                              data-has-start-content="true"
                              className="w-full font-normal bg-transparent !outline-none placeholder:text-foreground-500 focus-visible:outline-none data-[has-start-content=true]:ps-1.5 data-[has-end-content=true]:pe-1.5 text-small group-data-[has-value=true]:text-default-foreground"
                              aria-label="Phone Number"
                              type="text"
                              placeholder="(Country code)Phone number"
                              id="react-aria7722523593-:ri:"
                              aria-labelledby="react-aria7722523593-:ri: react-aria7722523593-:rj:"
                              value=""
                              title=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear-both mb-2 border-b border-gray-300 py-2 undefined"></div>
              </div>
              <div className="my-5">
                <div className="flex h-11 justify-between align-middle">
                  <div className="flex items-center gap-2">
                    <img
                      alt="star_png"
                      loading="lazy"
                      width="25"
                      height="25"
                      decoding="async"
                      data-nimg="1"
                      className="rounded-full"
                      srcSet="/tg-star.png 1x, /tg-star.png 2x"
                      src="/tg-star.png"
                      style={{ color: "transparent" }}
                    />
                    <div
                      className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none"
                      data-loaded="true"
                    >
                      <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                        <p className="text-xl font-bold">3139</p>
                      </div>
                    </div>
                    <p className="mt-1 text-xs">($40.8)</p>
                  </div>
                  <div className="relative w-5/12">
                    <div className="absolute right-0  z-10 -translate-y-2/4 ">
                      <div className="min-w-14 flex items-center justify-center rounded-full px-2 h-5 bg-[#f8f8ff]">
                        <span className="font-Poppins mr-1 font-bold text-[#766ffa] text-xs">
                          +100
                        </span>
                        <img
                          alt="score"
                          loading="lazy"
                          width="14"
                          height="14"
                          decoding="async"
                          data-nimg="1"
                          srcSet="/score.svg 1x, /score.svg 2x"
                          src="/score.svg"
                          style={{ color: "transparent" }}
                        />
                      </div>
                    </div>
                    <button
                      className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 text-small gap-2 rounded-full [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none text-default-foreground data-[hover=true]:opacity-hover h-full w-full bg-indigo-500"
                      type="submit"
                    >
                      <p className="font-bold text-white">Buy Now</p>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}
