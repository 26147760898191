import { getHttpMock } from "../../utils";
import { httpPost } from "../../utils/httpRequest";
import { getMockTapOreBoxInfos } from "./mock";

/**
 * 获取敲击宝箱信息
 * @returns
 * @see https://candy-porpoise-601.notion.site/2b0d1016b45641ec8eef32b2a1b19509
 */
export async function getTapOreBoxInfos() {
  const reqData = await httpPost("/v1/farming/getTapOreBoxInfos");
  return getHttpMock() ? getMockTapOreBoxInfos() : reqData;
}

export async function checkBox(index) {
  const reqData = await httpPost("/v1/farming/checkBox", { index });
  return reqData;
}

export async function tapBox(index, tapCount) {
  const reqData = await httpPost("/v1/farming/tapBox", { index, tapCount });
  return reqData;
}

/**
 * 领取/挖取MyPoints
 * @param {*} index
 * @returns
 * @see https://candy-porpoise-601.notion.site/MyPoints-16fdca88b36d40ae8595dc06c7c0d7e5
 */
export async function ploughing(index) {
  const reqData = await httpPost("/v1/farming/ploughing", { index });
  return reqData;
}

/**
 * 获取MyPoints信息
 * @param {*} index
 * @returns
 * @see https://candy-porpoise-601.notion.site/MyPoints-8386e1b4aa4a4046940a93b6a94ad869
 */
export async function getFarmerInfos(index) {
  const reqData = await httpPost("/v1/farming/getFarmerInfos", { index });
  return reqData;
}
