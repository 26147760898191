import React from "react";
import { Outlet, Link, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyComponent = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    // 处理关闭按钮点击事件
    console.log("关闭按钮被点击");
    navigate("/game");
  };

  return (
    <div className="pointer-events-none fixed top-2 right-2 z-50">
      <button
        onClick={handleClose}
        className="pointer-events-auto w-8 h-8 rounded-full bg-blue-300 text-white flex items-center justify-center focus:outline-none"
      >
        ×
      </button>
    </div>
  );
};

export default function Layout() {
  return (
    <body style={{ height: "100%" }}>
      <ToastContainer />
      <MyComponent />
      <div
        data-overlay-container="true"
        className="w-full h-full"
        style={{ height: "590px" }}
      >
        <div
          role="region"
          aria-label="Notifications (F8)"
          tabIndex="-1"
          style={{ pointerEvents: "none" }}
        >
          <ol
            tabIndex="-1"
            className="fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]"
          ></ol>
        </div>
        <Outlet />
      </div>
    </body>
  );
}
