import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import HistoryItem from "../../components/HistoryItem";
import TabBar from "./components/TabBar";
import { queryHistory } from "../../api/assets";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading/Loading";
import EmptySvg from "../../components/svg/EmptySvg";
import { useTranslation } from "react-i18next";

export default function HistoryPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [historys, setHistorys] = useState([]);
  const [loading, setLoading] = useState(true);

  const getFarmerHistory = async (symbol) => {
    try {
      setLoading(true);
      const resp = await queryHistory(symbol);
      setHistorys(resp?.historys);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFarmerHistory("Pika");
  }, []);

  return (
    <div
      className="h-screen overflow-hidden select-none text-white p-4"
      style={{
        background: `linear-gradient(0deg, #000000, #000000), linear-gradient(360deg, #434343 0%, #000000 100%)`,
      }}
    >
      <Header onClick={() => navigate("/me")} title="History" />
      <TabBar fn={getFarmerHistory} />
      {loading ? (
        <Loading color="rgba(255,255,255,0.5)" type="bubbles" showText />
      ) : historys?.length > 0 ? (
        <ul className="h-full overflow-auto pb-28">{historys?.map((item) => <HistoryItem {...item} />)}</ul>
      ) : (
        <div className="h-[75vh] flex flex-col items-center justify-center text-[rgba(255,255,255,0.5)]">
          <EmptySvg />
          <span>{t("pika.No History")}</span>
        </div>
      )}
    </div>
  );
}
