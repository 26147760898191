export default function Button({ text, disabled, margin, onClick }) {
  return (
    <button
      className="py-3.5 text-center flex-1 rounded-full text-black font-[800] text-[17px]"
      style={{
        background: "linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)",
        marginLeft: margin
      }}
      disabled={disabled}
      onClick={onClick}
    >{ text }</button>
  )
}