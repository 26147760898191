import { useLocation, useNavigate } from "react-router-dom";

function TabBar(props) {
  const { tabs, onChange, value } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isActiveClassName =
    "mt-2 w-full text-[0.6875rem] font-[500] text-white";
  const notActiveClassName =
    "mt-2 w-full text-[0.6875rem] font-[500] text-white";
  return (
    <div
      className="fixed bottom-0 z-10 mx-0 border-0 p-0 max-w-3xl select-none"
      id="page_btm_navagite_bar"
      style={{ width: "100%", border: "none", zIndex: 9999 }}
    >
      <div className="relative">
        <div className="relative shadow-black/5 shadow-none rounded-large w-full">
          <img
            src="/footerbar.png"
            className="relative z-10 w-full opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-large mx-0 p-0 shadow-2xl"
            alt="footerbar"
            data-loaded="true"
          />
        </div>
        <div className="absolute top-[30%] left-0 right-0 bottom-0 z-20 mx-0 -mt-4 w-full flex justify-around items-baseline px-3">
          {tabs?.map((item, index) => {
            return (
              <div
                key={index}
                id="text_arr"
                className="flex flex-col justify-center items-center"
                style={{
                  width: "75px",
                }}
              >
                <div
                  className="flex cursor-pointer justify-center"
                  onClick={() => navigate(item.path)}
                >
                  <div
                    className="relative shadow-black/5 shadow-none rounded-large"
                    style={{ maxWidth: "20" }}
                  >
                    {location.pathname === item.path
                      ? item.svg[0]
                      : item.svg[1]}
                  </div>
                </div>
                <div
                  className="flex cursor-pointer text-center"
                  onClick={() => navigate(item.path)}
                >
                  <div
                    className={
                      location.pathname === item.path
                        ? isActiveClassName
                        : notActiveClassName
                    }
                  >
                    {item.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TabBar;
