import { useInterval } from "ahooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function GuideCheckModal(props) {
  const { t } = useTranslation();
  const { onClick, onClose, title, loading } = props;
  const [loadingStr, setLoadingStr] = useState(".");

  function onCheck() {
    onClick?.();
  }

  useInterval(() => {
    if (loading) {
      if (loadingStr.length >= 3) {
        setLoadingStr(".");
      } else {
        setLoadingStr(`${loadingStr}.`);
      }
    }
  }, 300);

  return (
    <div className="fixed z-[1000] top-0 left-0 w-full h-full">
      <div className="w-full h-full flex items-center justify-center bg-[#00000080]">
        <div
          className="w-[320px] h-[262px] relative"
          style={{
            backgroundImage: "url('/guid-check-modal-bg.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            objectFit: "cover",
          }}
        >
          <img
            className="w-[16px] h-[16px] absolute z-40 right-[25px] top-[25px]"
            onClick={onClose}
            src="/comom-close.png"
          />
          <p className="text-white font-bold text-xl bottom-[90px] absolute w-full text-center">
            {t(`pika.${title}`)}
          </p>

          <div
            onClick={onCheck}
            style={{ cursor: "pointer" }}
            className="w-[286px] h-10 px-[15px] left-[50%] top-[190px] translate-x-[-50%] py-3.5 bg-gradient-to-r from-[#8bfff8] to-[#effcca] rounded-[10px] justify-center items-center gap-[5px] inline-flex relative overflow-hidden"
          >
            <div className="text-black text-lg font-extrabold relative z-20 flex gap-2">
              {loading ? loadingStr : t("pika.Check")}
            </div>
            {loading && (
              <div className="absolute w-full h-full left-0 top-0 rounded-[30px] mix-blend-color-burn bg-gradient-to-r from-[rgba(255,255,255,0)] to-[#64FF1C] animate-[shimmer_1.2s_linear_infinite] transition-all" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuideCheckModal;
