export function CheckedSvg() {
  return (
    <svg
      width='14'
      height='10'
      viewBox='0 0 14 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.63623 4.13636L4.99987 8.5L12.9999 0.5'
        stroke='url(#paint0_linear_849_1412)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_849_1412'
          x1='6.81805'
          y1='0.5'
          x2='6.81805'
          y2='8.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00D4FF' />
          <stop offset='1' stop-color='#08FFF0' />
        </linearGradient>
      </defs>
    </svg>
  )
}
