import React from "react";
import { Outlet, Link, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Layout() {
  return (
    <div data-overlay-container="true">
      <ToastContainer />
      <div
        role="region"
        aria-label="Notifications (F8)"
        tabIndex="-1"
        style={{ pointerEvents: "none" }}
      >
        <ol
          tabIndex="-1"
          className="fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]"
        ></ol>
      </div>
      <Outlet />
    </div>
  );
}
