export default function VerifySvg() {
  return (
    <svg
      width='42'
      height='42'
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='42' height='42' rx='8' fill='white' fill-opacity='0.1' />
      <g clip-path='url(#clip0_733_1236)' filter='url(#filter0_d_733_1236)'>
        <path
          d='M18.462 26.9385C18.032 27.3732 17.5973 27.8016 17.1689 28.2347C17.0664 28.3387 16.9562 28.4024 16.8289 28.4024C16.7793 28.4024 16.7265 28.3931 16.6706 28.3713C16.4672 28.2968 16.3694 28.1462 16.3601 27.9351C16.3539 27.8016 16.357 27.6681 16.3539 27.5346C16.3493 27.3763 16.3477 27.3188 16.2856 27.3188C16.2515 27.3188 16.2002 27.3359 16.1211 27.3623C15.6383 27.5222 15.1571 27.6836 14.6743 27.8435C14.6013 27.8684 14.5268 27.8854 14.4554 27.8854C14.3871 27.8854 14.3188 27.8699 14.2505 27.8327C14.044 27.7162 13.9664 27.5098 14.0487 27.2568C14.2086 26.7616 14.3778 26.2679 14.5408 25.7727C14.6122 25.5585 14.6075 25.5538 14.3762 25.5492C14.2365 25.5476 14.0968 25.5492 13.9571 25.543C13.7537 25.5336 13.6094 25.4343 13.5333 25.2418C13.4604 25.054 13.5069 24.891 13.6435 24.7528C14.0922 24.3026 14.5423 23.8525 14.9925 23.4038C15.0732 23.3231 15.1695 23.2734 15.2735 23.2734C15.2844 23.2734 15.2952 23.2734 15.3045 23.275C15.5483 23.2781 15.6833 23.3868 15.7718 23.6351C16.1149 24.6131 16.7389 25.3536 17.6439 25.8565C17.8442 25.9668 18.0537 26.0568 18.2711 26.1313C18.6638 26.2648 18.7523 26.6451 18.462 26.9385Z'
          fill='#17FF9E'
        />
        <path
          d='M28.8282 13.4269C28.7832 13.1382 28.7677 13.1257 28.4712 13.0714C28.1964 13.0202 27.9216 13 27.6453 13C27.3752 13 27.1051 13.0186 26.8365 13.0466C25.7577 13.1583 24.7052 13.399 23.6837 13.7669C21.8753 14.4188 20.2795 15.3922 18.9879 16.8343C18.9289 16.8995 18.8684 16.9212 18.7923 16.9212C18.7737 16.9212 18.755 16.9197 18.7349 16.9181C18.1915 16.8591 17.6467 16.7644 17.1018 16.7644H17.0645C16.0928 16.7722 15.3352 17.2177 14.809 18.042C14.5218 18.4922 14.2362 18.9439 13.949 19.3957C13.682 19.8148 13.4088 20.2277 13.1495 20.6515C12.7816 21.2538 13.1294 21.9772 13.8139 22.0284C14.6382 22.0905 15.4625 22.1402 16.2868 22.1852C16.4358 22.193 16.4995 22.2333 16.5135 22.3917C16.5631 22.9164 16.7029 23.4162 16.9838 23.868C17.5318 24.7466 18.3297 25.2294 19.348 25.3644C19.7315 25.4156 19.7206 25.4187 19.7485 25.8146C19.8029 26.6063 19.8619 27.3995 19.9364 28.1897C19.983 28.6833 20.3633 29 20.7871 29C20.9547 29 21.1301 28.9503 21.2947 28.8432C22.2152 28.2409 23.1451 27.6479 24.0377 27.0052C24.8697 26.406 25.2314 25.5616 25.1243 24.5293C25.0793 24.0868 25.042 23.6429 24.997 23.2004C24.9831 23.0638 25.0156 22.9598 25.1228 22.862C26.4283 21.6652 27.3597 20.2168 27.9961 18.5729C28.5286 17.1975 28.8468 15.7787 28.8919 14.3024C28.8934 14.2605 28.895 14.2186 28.895 14.1782C28.8996 13.9252 28.867 13.6753 28.8282 13.4269ZM23.9445 20.2929H23.9275C22.6933 20.2836 21.5865 19.1783 21.6129 17.9566C21.644 16.5083 22.7353 15.5753 24.0066 15.5753H24.0501C25.292 15.5955 26.3367 16.6806 26.3212 17.9427C26.3041 19.2544 25.2516 20.2929 23.9445 20.2929Z'
          fill='#17FFF7'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_733_1236'
          x='7'
          y='7'
          width='28'
          height='28'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0.94 0 0 0 0 1 0 0 0 0.6 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_733_1236'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_733_1236'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_733_1236'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(13 13)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
