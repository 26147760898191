import { useNavigate } from "react-router-dom";
import HistoryItem from "../../../components/HistoryItem";
import { useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import EmptySvg from "../../../components/svg/EmptySvg";
import { useTranslation } from "react-i18next";

export default function History({ data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector(state => state.assets.loading);

  return (
    <div className="mt-4 pt-4">
      <div className="flex items-center justify-between px-2">
        <span className="font-semibold">{t("pika.New History")}</span>
        <span
          className="flex items-center text-[#8BFFF8] cursor-pointer after:w-1.5 after:h-1.5 after:border-l-0 after:border-b-0 after:border-t after:border-r after:border-solid after:border-[#8BFFF8] after:rotate-45 after:ml-1"
          onClick={() => navigate("/me/history")}
        >{t("pika.More")}</span>
      </div>
      {
        loading ? (
          <Loading color="rgba(255,255,255,0.5)" type="bubbles" showText />
        ) : (
          <ul className="list-none list-outside">
            {
              data?.length > 0 ? data?.slice(0,5)?.map((item, index) => (
                <HistoryItem key={index} {...item} />
              )) : (
                <div className="h-[30vh] flex flex-col items-center justify-center text-[rgba(255,255,255,0.5)]">
                  <EmptySvg />
                  <span>{t("pika.No History")}</span>
                </div>
              )
            }
          </ul>
        )
      }
    </div>
  )
}