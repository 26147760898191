export default function TapActiveSvg() {
  return (
    <div
      className="w-11 h-11 bg-white rounded-full flex items-center justify-between"
      style={{
        background: "linear-gradient(90deg, #16FFF7 0%, #FFFBE8 100%)"
      }}
    >
      <svg className="absolute top-0 left-0 bottom-0 right-0 m-auto" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.2699 25.6823C22.6035 26.7159 20.5785 27.0159 18.6379 26.519L17.5762 26.2448L11.0465 24.5667C9.8629 24.262 9.11055 23.0479 9.36368 21.8526C9.6168 20.6573 10.7816 19.9354 11.9629 20.2401L14.2082 20.8167L11.8223 16.9729L9.08711 12.5643C8.42852 11.5049 8.71915 10.1339 9.73399 9.50339C10.7488 8.87292 12.1059 9.22214 12.7645 10.2815L15.359 14.4651C15.4363 14.5893 15.5957 14.6315 15.7152 14.5565L21.141 11.1909C22.2707 10.4878 23.7824 10.8768 24.5137 12.0581L26.4824 15.2292C28.7301 18.8503 27.7387 23.5307 24.2699 25.6823Z" fill="black"/>
        <path d="M3 13.25H5" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        <path d="M6 4L7 5.73205" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        <path d="M14.5811 4.37891L14.0634 6.31076" stroke="black" strokeWidth="2" strokeLinecap="round"/>
      </svg>
    </div>
  )
}