function CloseBtn(props) {
  const { onClose } = props;

  return (
    <img
      className="w-[16px] h-[16px] "
      onClick={(e) => {
        e.stopPropagation();
        onClose?.();
      }}
      src="/comom-close.png"
    />
  );
}

export default CloseBtn;
