import { useEffect, useState } from "react";
import BadgeItem from "./components/BadgeItem";
import PageHead from "./components/PageHead";
import UserAssets from "./components/UserAssets";
import UserInfo from "./components/UserInfo";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssets } from "../../store/features/assetsSlice";
import { setJumpChain } from "../../store/features/commonSlice";
import { useJumpChain } from "../../hooks/useJumpChain";
import History from "./components/History";
import VersionInfo from "./components/VersionInfo";

export default function Page() {
  const dispatch = useDispatch();
  const assetsInfo = useSelector((state) => state.assets.assetsInfo);
  useJumpChain({});

  useEffect(() => {
    dispatch(fetchAssets());
  }, []);

  return (
    <div className="w-full h-screen select-none overflow-hidden round-lg bg-cover bg-top bg-no-repeat px-3 py-4 pb-28 flex flex-col space-y-3 text-white">
      <PageHead {...assetsInfo} />
      <div className="h-full overflow-auto flex flex-col space-y-3">
        <UserAssets />
        <UserInfo {...assetsInfo} />
        <VersionInfo />
        <History data={assetsInfo?.historys} />
      </div>
    </div>
  );
}
