export default function RankActiveSvg() {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.52465 2.90881C2.75617 2.90881 0.51159 5.16782 0.51159 7.95444C0.51159 10.7411 2.75617 13 5.52465 13C8.29313 13 10.5382 10.741 10.5382 7.95437C10.5382 5.16776 8.29397 2.90875 5.52465 2.90875V2.90881ZM8.10281 7.50432L7.11442 8.47405L7.34757 9.8434C7.4261 10.303 7.16499 10.5042 6.74684 10.2829L5.52485 9.63625L4.30317 10.283C3.89309 10.4999 3.62249 10.3124 3.70231 9.84353L3.93566 8.47418L2.94727 7.50425C2.61538 7.17879 2.70911 6.86205 3.17691 6.79348L4.54282 6.5936L5.15382 5.34794C5.35896 4.9296 5.6876 4.92128 5.89671 5.34794L6.50771 6.5936L7.87356 6.79348C8.33207 6.86049 8.44146 7.17243 8.103 7.50425L8.10281 7.50432ZM10.2114 0H7.11169C7.03241 0.000802765 6.9557 0.0282356 6.89389 0.07789C6.83209 0.127544 6.78877 0.196537 6.7709 0.27378L5.8154 2.17002C5.8154 2.17002 7.86134 2.29411 9.07001 3.46522L10.4972 0.56693C10.5426 0.507481 10.568 0.435188 10.5697 0.360425C10.57 0.265121 10.5324 0.17361 10.4652 0.106019C10.398 0.0384283 10.3067 0.000292631 10.2114 0ZM5.18392 2.17016L4.22855 0.273845C4.21065 0.196601 4.16731 0.127612 4.1055 0.0779507C4.04368 0.0282898 3.96698 0.000837472 3.88769 0L0.787775 0C0.740591 0.000153386 0.6939 0.00959991 0.650367 0.0278001C0.606835 0.0460003 0.567314 0.0725976 0.534062 0.106073C0.50081 0.139549 0.474478 0.179247 0.456569 0.2229C0.438661 0.266553 0.429527 0.313306 0.42969 0.36049C0.431371 0.435263 0.456748 0.507571 0.502165 0.566995L1.92937 3.46541C3.13798 2.29418 5.18392 2.17016 5.18392 2.17016Z" fill="url(#paint0_linear_1_433)"/>
      <defs>
      <linearGradient id="paint0_linear_1_433" x1="0.429688" y1="6.5" x2="10.5697" y2="6.5" gradientUnits="userSpaceOnUse">
      <stop stopColor="#16FFF7"/>
      <stop offset="1" stopColor="#FFFBE8"/>
      </linearGradient>
      </defs>
    </svg>
  )
}