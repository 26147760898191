import { useEffect, useState } from "react";
import { getMockTaskData } from "../../api/task/mock";
import DescriptionInfo from "./components/DescriptionInfo";
import InviteBtn from "./components/InviteBtn";
import MyReward from "./components/MyReward";
import { getFarmerInvites, getFarmerInvitesCeche } from "../../api/friends";
import RankList from "./components/RankList";
import { useJumpChain } from "../../hooks/useJumpChain";
import { createShakeAni } from "../../utils/cssAni";
import TabBar from "../../components/TabBar";
import Club from "./Club";
import InfluenceInfo from "./components/InfluenceInfo";
import { useSelector } from "react-redux";

const linkKeys = [203, 207, 106, 112, 113, 114, 115, 116, 117, 999];

const tabs = [
  { value: "Friends", label: "Friends", icon: "", disabled: false },
  { value: "Club", label: "Club", icon: "", disabled: true },
];

const getLinkData = () => {
  const result = {};
  linkKeys.forEach((item) => {
    result[item] = {
      btnFn: () => {
        createShakeAni("inviteBtn");
      },
    };
  });
  return result;
};

export default function FriendsPage() {
  const [pageInfo, setPageInfo] = useState({});
  const [curTab, setCurTab] = useState("Friends");
  const { accessData } = useSelector((state) => state.common.loginInfo);
  const [inviteInfo, setInviteInfo] = useState({});

  useJumpChain(getLinkData());

  const getPageInfo = async () => {
    const res = await getFarmerInvites();
    console.log(res);
    setPageInfo(res);
  };

  const getInviteInfo = async () => {
    const res = await getFarmerInvitesCeche();
    setInviteInfo(res);
  };

  useEffect(() => {
    getPageInfo();
    getInviteInfo();
  }, []);

  const onTabChange = (key) => {
    console.log(key);
    setCurTab(key);
    if (key === "Friends") {
      getPageInfo();
    }
  };

  return (
    <div className="w-full h-screen overflow-hidden select-none p-4 pb-8">
      <TabBar tabs={tabs} value={curTab} onClick={onTabChange} />
      <>
        {curTab === "Friends" && (
          <div className="h-full overflow-auto">
            <MyReward data={pageInfo} />
            {/* <InfluenceInfo data={inviteInfo} /> */}
            <InviteBtn />
            <DescriptionInfo />
            <RankList data={inviteInfo?.datas} />
          </div>
        )}
        {curTab === "Club" && <Club />}
      </>
    </div>
  );
}
