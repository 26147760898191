function TimeBarItem(props) {
  const { type, time } = props;

  return (
    <div
      className="w-[40px] h-[28px] flex items-center justify-center border-2 border-[#FFFFFF33]"
      style={{ borderRadius: "7px" }}
    >
      <span className="font-bold text-[16px] text-[#ffc164]">{time}</span>
      <span className="font-bold text-[12px] text-[#fff]">{type}</span>
    </div>
  );
}

export default TimeBarItem;
