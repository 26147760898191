export default function UsdtSvg() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#26A69A"/>
      <path d="M14.5 5H5.5V7.5H14.5V5Z" fill="white"/>
      <path d="M10 9C7.7715 9 4 9.3685 4 10.75C4 12.1315 7.7715 12.5 10 12.5C12.2285 12.5 16 12.1315 16 10.75C16 9.3685 12.2285 9 10 9ZM10 11.5C7.2385 11.5 5 11.0525 5 10.5C5 9.9475 7.2385 9.5 10 9.5C12.7615 9.5 15 9.9475 15 10.5C15 11.0525 12.7615 11.5 10 11.5Z" fill="white"/>
      <path d="M10 10.5C10.5475 10.5 11.0465 10.4815 11.5 10.451V5H8.5V10.451C8.9535 10.4815 9.4525 10.5 10 10.5Z" fill="white"/>
      <path d="M10.8615 11.4831C10.806 11.4851 10.75 11.4866 10.6935 11.4881C10.466 11.4946 10.236 11.4991 10 11.4991C9.764 11.4991 9.534 11.4946 9.3065 11.4886C9.25 11.4871 9.194 11.4856 9.1385 11.4836C8.921 11.4761 8.707 11.4666 8.5 11.4536V16.4991H11.5V11.4531C11.293 11.4661 11.079 11.4756 10.8615 11.4831Z" fill="white"/>
    </svg>
  )
}