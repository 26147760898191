import { createSlice } from "@reduxjs/toolkit";
import { boolean, number } from "@telegram-apps/sdk";

const initialState = {
  taskInfo: {
    id: undefined,
    isCheckFailed: false
  }
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTaskData: (state, action) => {
      state.taskInfo = action.payload;
    }
  }
});

export const { setTaskData } = taskSlice.actions;

// 默认导出
export default taskSlice.reducer;