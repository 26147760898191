import { useSelector } from "react-redux";
import CountUp from "react-countup";
import RightArrow from "../svg/RightArrow";
import UsdtSvg from "../svg/UsdtSvg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AssetsCom(props) {
  const { t } = useTranslation();
  const { onClick } = props
  const location = useLocation()
  const assetsInfo = useSelector(state => state.assets.assetsInfo)

  return (
    <>
      <div className="flex items-center justify-between text-sm">
        <span className="text-[#FFFFFFB2]">{t("pika.Total Balance")}</span>
        {location.pathname === "/me" && <a className="flex items-center space-x-0.5 cursor-pointer" onClick={onClick}>
          <span>{t("pika.Assets")}</span>
          <RightArrow />
        </a>}
      </div>
      <div className="flex items-baseline space-x-2 mt-2">
        <span
          className="text-3xl font-boldtext-center font-[700] leading-[2rem] bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] bg-clip-text text-transparent"
        >
          {/* <CountUp start={0} end={assetsInfo?.totalBalance || 0} duration={1.5} /> */}
          {assetsInfo?.totalBalance || 0}
        </span>
        <div className="flex items-center space-x-1">
          <UsdtSvg />
          <span>USDT</span>
        </div>
      </div>
    </>
  )
}