function DailySmallBox(props) {
  const { type, status } = props;

  const isClose = Number(status) === 0;

  const src = "/treasure-no-complete-mini.png";
  const openSrc = "/treasure-complete-mini.png";

  return (
    <img
      alt="treasure"
      loading="lazy"
      width="24"
      height="24"
      decoding="async"
      data-nimg="1"
      className="mr-[6px]"
      src={isClose ? src : openSrc}
      style={{ color: "transparent" }}
    />
  );
}

export default DailySmallBox;
