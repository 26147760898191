export default function RankPage() {
  return (
    <div className="px-4 select-none">
      <div id="choose_btn" className="flex justify-between">
        <div className="relative z-10 mr-2 w-1/2 px-0">
          <button
            className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full py-3 text-sm bg-[#fff] border-1 font-bold text-black border-[#766ffa]"
            type="button"
          >
            $BBY Farmed
          </button>
        </div>
        <div className="relative z-10 w-1/2 px-0">
          <button
            className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full py-3 text-sm bg-[#fff] border-slate-300 font-normal text-[#767a81] border-0"
            type="button"
          >
            $Boosts Farmed
          </button>
        </div>
      </div>
      <div className="mt-2 flex flex-row" id="user-row">
        <div className="w-8 text-center text-sm text-[#979797]">#</div>
        <div className="flex-grow text-sm text-[#979797]">User</div>
        <div className="w-30 text-sm text-[#979797]">⭐ Total</div>
      </div>
      <div className="mb-4" style={{ minHeight: "400px" }}>
        <div className="mt-2 flex flex-row items-center">
          <img
            alt="/first-ld.png"
            loading="lazy"
            width="24"
            height="24"
            decoding="async"
            data-nimg="1"
            className="mr-2"
            srcSet="/_next/image?url=%2Ffirst-ld.png&amp;w=32&amp;q=75 1x, /_next/image?url=%2Ffirst-ld.png&amp;w=48&amp;q=75 2x"
            src="/_next/image?url=%2Ffirst-ld.png&amp;w=48&amp;q=75"
            style={{ color: "transparent" }}
          />
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-19.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">TO...BA</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">29450</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <img
            alt="/second-ld.png"
            loading="lazy"
            width="24"
            height="24"
            decoding="async"
            data-nimg="1"
            className="mr-2"
            srcSet="/_next/image?url=%2Fsecond-ld.png&amp;w=32&amp;q=75 1x, /_next/image?url=%2Fsecond-ld.png&amp;w=48&amp;q=75 2x"
            src="/_next/image?url=%2Fsecond-ld.png&amp;w=48&amp;q=75"
            style={{ color: "transparent" }}
          />
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-19.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">De...O1</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">29108</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <img
            alt="/third-ld.png"
            loading="lazy"
            width="24"
            height="24"
            decoding="async"
            data-nimg="1"
            className="mr-2"
            srcSet="/_next/image?url=%2Fthird-ld.png&amp;w=32&amp;q=75 1x, /_next/image?url=%2Fthird-ld.png&amp;w=48&amp;q=75 2x"
            src="/_next/image?url=%2Fthird-ld.png&amp;w=48&amp;q=75"
            style={{ color: "transparent" }}
          />
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-19.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">ke...pt</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">24330</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">4</div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-7.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-7.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-7.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Al...ak</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">23874</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">5</div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-10.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-10.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-10.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Sa...15</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">22460</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">6</div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-17.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-17.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-17.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">BO...ee</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">21703</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">7</div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-2.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-2.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-2.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Se...el</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">21641</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">8</div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-9.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-9.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-9.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">su...24</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">19588</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">9</div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-17.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-17.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-17.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Ra...ra</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">17700</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            10
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-0.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-0.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-0.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">eg...an</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">15495</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            11
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-19.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Ao...23</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">15220</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            12
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-18.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-18.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-18.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">te...ok</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">14890</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            13
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-6.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-6.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-6.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">li...hh</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">14760</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            14
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-5.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-5.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-5.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Mi...44</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">14490</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            15
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-17.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-17.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-17.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Ad...el</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">14470</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            16
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-5.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-5.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-5.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Ha...an</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">14190</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            17
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-19.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-19.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Cr...p2</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">13790</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            18
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-4.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-4.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-4.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">lv...an</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">13280</div>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <div className="w-8 text-center text-sm font-bold text-[#000]">
            19
          </div>
          <div className="flex flex-grow flex-row items-center">
            <div
              data-loaded="true"
              className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
            >
              <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
                <div
                  className="relative inline-flex"
                  style={{ width: "39px", paddingTop: "3px" }}
                >
                  <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                        position: "relative",
                        width: "35px",
                        height: "35px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        zIndex: "10",
                      }}
                    >
                      <img
                        alt="photo"
                        loading="lazy"
                        width="35"
                        height="35"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/_next/image?url=%2Favatar-12.png&amp;w=48&amp;q=75 1x, /_next/image?url=%2Favatar-12.png&amp;w=96&amp;q=75 2x"
                        src="/_next/image?url=%2Favatar-12.png&amp;w=96&amp;q=75"
                        style={{
                          color: "transparent",
                          position: "absolute",
                          inset: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "absolute",
                      width: "39px",
                      height: "39px",
                      overflow: "hidden",
                      backgroundColor: "rgb(103, 109, 254)",
                      zIndex: "5",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ml-2 text-sm font-bold">Sk...YT</div>
          </div>
          <div className="w-30 text-sm  text-[#000]">12455</div>
        </div>
      </div>
    </div>
  );
}
