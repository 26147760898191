import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  code: 0,
  headId: 0,
  tgName: "",
  tgInvateLink: "",
  totalBalance: "",
  $CFUN: 0,
  $Pika: 0,
  jumpChain: {
    page: "",
    tab: "",
    btn: "",
    link: "",
    hasCustomize: "",
    id: "",
  },
  isLogin: false,
  loginInfo: {},
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCommonInfo: (state, action) => {
      const { code, headId, tgName, tgInvateLink, totalBalance, $CFUN, $Pika } =
        action?.payload;
      state.code = code;
      state.headId = headId;
      state.tgName = tgName;
      state.tgInvateLink = tgInvateLink;
      state.totalBalance = totalBalance;
      state.$CFUN = $CFUN;
      state.$Pika = $Pika;
    },

    setJumpChain: (state, action) => {
      state.jumpChain = action?.payload;
    },
    setIsLogin: (state, action) => {
      state.isLogin = action?.payload;
    },
    setLoginInfo: (state, action) => {
      state.loginInfo = action?.payload;
    },
  },
});

export const { setCommonInfo, setJumpChain, setIsLogin, setLoginInfo } =
  commonSlice.actions;

// 默认导出
export default commonSlice.reducer;
