export default {
    "2": {
        "id": 2,
        "Title": "SURVIVOR LEVEL RANK",
        "Describe": "Play SURVIVOR, improve your level, and enter the leaderboard",
        "Winners": 50,
        "Endin": "2024 07-14 20:00:00",
        "Reward total": 72000,
        "Reward type": "CFUN",
        "Sort": 5,
        "More details URL": "https://crystalfun.medium.com/odyssey-2-0-survivor-genesis-test-event-582c58074ba6",
        "Pic": "/SURVIVOR-LEVEL-RANK.jpg"
    },
    "3": {
        "id": 3,
        "Title": "SURVIVOR ENDLESS TRILAS RANK",
        "Describe": "In the SURVIOR, participate in ENDLESS TRILAS and enter the leaderboard",
        "Winners": 50,
        "Endin": "2024 07-14 20:00:00",
        "Reward total": 72000,
        "Reward type": "CFUN",
        "Sort": 6,
        "More details URL": "https://crystalfun.medium.com/odyssey-2-0-survivor-genesis-test-event-582c58074ba6",
        "Pic": "/SURVIVOR-ENDLESS-TRILAS-RANK.jpg"
    },
    "4": {
        "id": 4,
        "Title": "STARFALL ELITE TEST",
        "Describe": "Participate in STARFILL Elite Test and complete the testing task",
        "Winners": 100,
        "Endin": "2024 05-28 20:00:00",
        "Reward total": 50000,
        "Reward type": "CFUN",
        "Sort": 7,
        "More details URL": "https://crystalfun.medium.com/crystal-funs-new-game-starfall-elite-captains-ready-to-go-8011adf17ac4",
        "Pic": "/STARFALL-ELITE-TEST.jpg"
    },
    "5": {
        "id": 5,
        "Title": "STARFALL CAPTURE BUG EXPERTS",
        "Describe": "During the STARFILL Elite Testing period, submit valid bugs",
        "Winners": 30,
        "Endin": "2024 05-28 20:00:00",
        "Reward total": 50000,
        "Reward type": "CFUN",
        "Sort": 8,
        "More details URL": "https://crystalfun.medium.com/crystal-funs-new-game-starfall-elite-captains-ready-to-go-8011adf17ac4",
        "Pic": "/STARFALL-CAPTURE-BUG-EXPERTS.jpg"
    },
    "6": {
        "id": 6,
        "Title": "OUTER Testing II",
        "Describe": "Participate in OUTER testing and win generous rewards",
        "Winners": 17,
        "Endin": "2024 04-18 20:00:00",
        "Reward total": 17,
        "Reward type": "NFT",
        "Sort": 9,
        "More details URL": "https://crystalfun.medium.com/outer-testing-ii-nft-lottery-winner-announcement-8672ea0d4110",
        "Pic": "/OUTER-SECONDARY-TEST.jpg"
    },
    "7": {
        "id": 7,
        "Title": "OUTER BattlePoint COMPETITION",
        "Describe": "Players who rank high in OUTER can win rewards",
        "Winners": 100,
        "Endin": "2024 04-18 20:00:00",
        "Reward total": 50000,
        "Reward type": "CFUN",
        "Sort": 10,
        "More details URL": "https://crystalfun.medium.com/complete-guide-to-outer-battlepoint-embrace-your-challenge-in-space-5181bb75a2b3",
        "Pic": "/OUTER-RANKING-COMPETITION.jpg"
    },
    "8": {
        "id": 8,
        "Title": "OUTER GROUP BATTLE",
        "Describe": "The group of OUTER with the most occupied planets wins",
        "Winners": 30,
        "Endin": "2024 04-18 20:00:00",
        "Reward total": 50000,
        "Reward type": "CFUN",
        "Sort": 11,
        "More details URL": "https://crystalfun.medium.com/outer-testing-ii-guild-face-to-face-vol-2-ama-session-recap-a4ecfa170d34",
        "Pic": "/OUTER-GROUP-BATTLE.jpg"
    },
    "9": {
        "id": 9,
        "Title": "SURVIVOR Quarterly Season",
        "Describe": "The top 500 players on the leaderboard can receive corresponding $CFUN tokens and NFT rewards",
        "Winners": 500,
        "Endin": "2024 11-15 23:59:59",
        "Reward total": 50000,
        "Reward type": "CFUN",
        "Sort": 1,
        "More details URL": "https://crystalfun.medium.com/survivor-new-season-user-guide-bc7bea779d4c",
        "Pic": "/SURVIVOR-QUARTERLY-SEASON.jpg",
        "Play and earn URL": "https://t.me/survivoriobot?start=d59ec9e537"
    },
    "10": {
        "id": 10,
        "Title": "SURVIVOR LEVEL NFT",
        "Describe": "After the ship reaches a certain level, you can obtain NFT",
        "Winners": "unlimited",
        "Endin": "Forever",
        "Reward total": "unlimited",
        "Reward type": "NFT",
        "Sort": 2,
        "More details URL": "https://crystalfun.medium.com/survivor-new-season-user-guide-bc7bea779d4c",
        "Pic": "/SURVIVOR-LEVEL-NFT.jpg",
        "Play and earn URL": "https://t.me/survivoriobot?start=d59ec9e537"
    },
    "11": {
        "id": 11,
        "Title": "SURVIVOR LEVEL RANK",
        "Describe": "Upgrade levels in the game to receive monthly rewards",
        "Winners": 100,
        "Endin": "Forever",
        "Reward total": 50000,
        "Reward type": "CFUN",
        "Sort": 3,
        "More details URL": "https://crystalfun.medium.com/survivor-new-season-user-guide-bc7bea779d4c",
        "Pic": "/SURVIVOR-LEVEL-RANK2.jpg",
        "Play and earn URL": "https://t.me/survivoriobot?start=d59ec9e537"
    }
}