import { useEffect, useState } from "react";
import CircleProgress from "../CircleProgress";

function MainContent(props) {
  const { progress, onClick, type, status, isLoading } = props;
  const showLock = false;
  const [cirleR, setCircleR] = useState(0);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const dom = document.getElementById("circle_progress");
      const domW = dom?.clientWidth;
      const domH = dom?.clientHeight;
      const r = domW > domH ? domH / 2 - 20 : domW / 2 - 20;
      setCircleR(r);
      setIsInit(true);
    }, 500);
  }, []);

  return (
    <div
      className="w-[88%] relative mt-[-20px] flex justify-center flex-1"
      id="circle_progress"
    >
      <div className="absolute w-full h-full top-0 left-0 z-10">
        <CircleProgress
          value={progress}
          r={cirleR}
          status={status}
          onClick={onClick}
        />
      </div>
      <div className="absolute w-full h-full top-0 left-0">
        {isInit && !isLoading && (
          <img
            style={{
              width: `${(cirleR - 10) * 2}px`,
              height: `${(cirleR - 10) * 2}px`,
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            src={`game_main_box_${type}.png`}
          />
        )}

        {status === "done" && (
          <img
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 50,
              transform: "translate(-50%,-50%)",
            }}
            src="box-checked.svg"
          />
        )}
      </div>
      {showLock && (
        <div className="absolute w-full h-full z-10 top-0 left-0">
          <div className="flex justify-center items-center w-full h-full">
            <img
              className="w-[40px] h-[40px]"
              src="https://assets.outer.gg/frontend/img/privilege/icon-gamer.png"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default MainContent;
