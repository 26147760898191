function AssetsIcon(props) {
  const { icon, value } = props;
  return (
    <div className="flex items-center w-[73px]">
      <img className="mr-[5px] w-[20px] h-[20px] " src={icon} />
      <span className="text-[14px] font-medium text-[#FFFFFF]">{value}</span>
    </div>
  );
}

export default AssetsIcon;
