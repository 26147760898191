import PageHead from "./components/PageHead";
import SingleTaskItem from "./components/SingleTaskItem";
import TaskItemsBlock from "./components/TaskItemsBlock";

function EarnPage() {
  return (
    <>
      <SingleTaskItem />
      <SingleTaskItem />
      <SingleTaskItem />
      <SingleTaskItem />
      <SingleTaskItem />
      <SingleTaskItem />
      <SingleTaskItem />
      <SingleTaskItem />
      <SingleTaskItem />
      <TaskItemsBlock />
      <TaskItemsBlock />
    </>
  );
}

export default EarnPage;
