import { useTonConnectModal } from "@tonconnect/ui-react"
import { useRippleEff } from "../../../hooks/useRippleEff";
import { useTranslation } from "react-i18next";

export default function ConnectBtn() {
  const { open } = useTonConnectModal();
  useRippleEff();
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="relative w-full rounded-lg py-3.5 text-black font-[700] text-[17px] overflow-hidden ripple"
      style={{
        background: `linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)`
      }}
      onClick={open}
    >
      {t("pika.Connect Wallet")}
    </button>
  )
}