import { useTranslation } from "react-i18next";

function TaskItem(props) {
  const { t } = useTranslation();
  const { onClick, data, src, txt, id } = props;
  const { isCompleted, en, link } = data || {};

  const btnLabel = isCompleted ? "Completed" : "Go";

  return (
    <div
      id={id}
      className="flex items-center w-[345px] h-[62px] justify-between mb-[15px]"
      style={{
        backgroundImage: "url('/guide-task-item-bg.svg')",
        boxShadow: `0px 0px 15px rgba(12.65, 211.38, 255, 0.20)`,
        padding: "10px",
        borderRadius: "10px",
        border: `1px rgba(255, 255, 255, 0.15) solid`,
        backgroundSize: "cover",
      }}
    >
      <img className="w-[30px] h-[30px] mr-[10px]" src={src} />
      <div>
        <p className="w-[189px] text-[15px] font-bold text-white">{t(`pika.${en}`)}</p>
        <img className="w-[189px] h-[13px]" src="./guide-point-50.png" />
      </div>
      {isCompleted ? (
        <img className="w-[81px] h-[29px]" src="/task-done-txt.png" />
      ) : (
        <button
          className={`w-[76px] h-[32px] bg-[#23FFF7] rounded-[8px] font-bold text-black `}
          onClick={() => {
            onClick?.(data);
          }}
        >
          {btnLabel}
        </button>
      )}
    </div>
  );
}

export default TaskItem;
