function TaskItem() {
  return (
    <div className="bg-white py-1 mt-0 rounded-none px-0 bg-gradient-to-r from-[#FFEFD1] to-[#FFF] border-b-1">
      <div className="px-1 py-1">
        <div className="flex items-center justify-between">
          <div className="flex flex-1 items-center">
            <div className="text-sm font-bold" style={{ maxWidth: "80%" }}>
              Refer friends to visit mini app
            </div>
            <div className="min-w-14 flex h-7 items-center justify-center rounded-full px-2 ml-1">
              <span className="font-Poppins mr-1 text-sm font-bold text-[#766ffa]">
                +20
              </span>
              <img
                alt="score"
                loading="lazy"
                width="14"
                height="14"
                decoding="async"
                data-nimg="1"
                srcSet="/score.svg 1x, /score.svg 2x"
                src="/score.svg"
                style={{ color: "transparent" }}
              />
            </div>
          </div>
          <div>
            <img
              alt="question"
              loading="lazy"
              width="15"
              height="15"
              decoding="async"
              data-nimg="1"
              srcSet="/login-left.svg 1x, /login-left.svg 2x"
              src="/login-left.svg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
        <div className="text-xs text-slate-400">
          20 $BBY for you and your friend
        </div>
      </div>
    </div>
  );
}
export default TaskItem;
