export default function UserSvg() {
  return (
    <svg
      width='9'
      height='11'
      viewBox='0 0 9 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 11H0V10.2821C0 8.7877 1.2592 7.57626 2.81254 7.57626H6.1875C7.74079 7.57626 9 8.7877 9 10.2821V11ZM4.50002 6.49392C2.63604 6.49392 1.125 5.04023 1.125 3.24697C1.125 1.45371 2.63604 0 4.50002 0C6.36397 0 7.87499 1.45371 7.87499 3.24697C7.87499 5.04023 6.36397 6.49392 4.50002 6.49392Z'
        fill='white'
        fill-opacity='0.7'
      />
    </svg>
  )
}
