import { useTranslation } from "react-i18next";
import RightArrowSvg from "../../../components/svg/RightArrowSvg";
import { APP_VERSION } from "../../../version";

function VersionInfo() {
  const { t } = useTranslation();
  return (
    <div
      className="p-2.5 text-white text-[12px] rounded-xl flex items-start justify-around w-full bg-[#24242480]"
    >
      <a href="https://crystalfun.medium.com/introducing-pika-e5b99a6875e9" target="_blank" rel="noreferrer" className="text-[12px] flex items-center space-x-1 hover:underline underline-offset-2">
        <span>{t("pika.About pika")}</span>
        <RightArrowSvg />
      </a>
      <span>{`${t("pika.Version")} ${APP_VERSION}`}</span>
    </div>
  );
}
export default VersionInfo;
