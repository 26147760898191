import { useState } from "react";
import TabBar from "../../../components/TabBar/index";
import ConnectBtn from "./ConnectBtn";
import { TonConnectButton } from "@tonconnect/ui-react";
import { useTonAddress, useTonWallet } from "@tonconnect/ui-react";

const tabsList = [
  {
    value: "farm",
    label: "Farm",
    icon: "/icon-c-color.png",
  },
  // {
  //   value: "sign",
  //   label: "Sign-in",
  //   icon: "/sign-in-icon.png",
  //   disabled: "true",
  // },
];

function HeadTab() {
  const [curTab, setCurTab] = useState("farm");
  const userFriendlyAddress = useTonAddress();
  const wallet = useTonWallet();

  console.log("TG Wallet address ::", userFriendlyAddress);

  console.log("all tWallet info ::", wallet);

  function onTabChange(data) {
    setCurTab(data);
  }

  return (
    <div className="flex items-center justify-between">
      <TabBar tabs={tabsList} value={curTab} onClick={onTabChange} />
      {/* <ConnectBtn /> */}
      <TonConnectButton />
    </div>
  );
}

export default HeadTab;
