import LazyLoad from 'react-lazyload'
import { bindWallet, noticeTaskInfo } from '../../../../api/task'
import ScoreColorSvg from '../../../../components/svg/ScoreColorSvg'
import { useDispatch, useSelector } from 'react-redux'
import { setJumpChain } from '../../../../store/features/commonSlice'
import { bindwalletFn } from '../../biz'
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import Content from './Content'
import { useTranslation } from 'react-i18next'

export default function TaskItem(props) {
  const { t } = useTranslation();
  const { data, onChange } = props
  const {
    en,
    pikaReward,
    noticeServer,
    id,
    link,
    avatar,
    state,
    checkType,
    isShow,
  } = data
  const dispatch = useDispatch()
  const walletAddress = useTonAddress()
  const [tonConnectUI, setOptions] = useTonConnectUI()
  const [showCheckModal, setShowCheckModal] = useState(false)
  const taskInfo = useSelector(state => state.task.taskInfo);

  useEffect(() => {
    const isbindingWellet = sessionStorage.getItem('bindingWellet') === '1'
    if (walletAddress && isbindingWellet) {
      bindwalletFn(walletAddress, () => {
        onChange(id, data)
      })
    }
  }, [walletAddress])

  const needNoticeServer = Number(noticeServer) === 1

  async function onTaskClick() {
    if (id === 18) {
      bindwalletFn(
        walletAddress,
        () => {
          onChange(id, data)
        },
        () => {
          tonConnectUI.openModal()
        }
      )
      return
    }

    if (needNoticeServer) {
      // TODO 需要处理跳转
      if (link?.indexOf('http') > -1) {
        // setShowCheckModal(true);
        if (link?.indexOf('https://t.me') > -1) {
          window.Telegram.WebApp.openTelegramLink(link)
        } else {
          window.Telegram.WebApp.openLink(link)
        }
      }
      setTimeout(() => {
        noticeTaskInfo(id)
        setTimeout(() => {
          onChange(id, data)
        }, 1000)
      }, 1000)
    } else {
      if (link) {
        try {
          const linkData = JSON.parse(link)
          dispatch(setJumpChain(linkData))
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  return (
    <>
      {Boolean(isShow) && (
        <li
          className={`w-full mx-auto rounded-xl shadow-lg bg-[#21212190] flex items-center justify-between hover:bg-[#212121] p-2.5 ${
            state === 0 && 'cursor-pointer'
          }`}
          style={{
            // background: 'rgba(33, 33, 33, 0.9)',
            zIndex: 999,
          }}
          onClick={onTaskClick}
        >
          <div className='flex items-center justify-between'>
            {/* <LazyLoad> */}
            <img
              className='h-8 w-8 rounded-full'
              src={`/avatars/${avatar}`}
              alt=''
            />
            {/* </LazyLoad> */}
            <div className='ml-3 overflow-hidden'>
              <p className='text-sm font-medium'>{t(`pika.${en}`)}</p>
              <p className='flex items-center space-x-1 text-sm font-bold'>
                <span className='bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-transparent bg-clip-text'>
                  +{pikaReward || 0}
                </span>
                <ScoreColorSvg />
              </p>
            </div>
          </div>
          {state === 0 ? (
            <div className=''>
              {checkType > 0 ? (
                <>
                  <div
                  className='w-[40px] h-[24px] flex items-center justify-center bg-[#23FFF7] rounded-[4px] text-black font-bold text-sm'
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowCheckModal(true)
                    if (link?.indexOf('http') > -1) {
                      if (link?.indexOf('https://t.me') > -1) {
                        window.Telegram.WebApp.openTelegramLink(link)
                      } else {
                        window.Telegram.WebApp.openLink(link)
                      }
                    }
                  }}
                >
                  Go
                </div>
                { taskInfo?.id === id && taskInfo?.isCheckFailed && 
                  <button
                    className='mt-1 w-[40px] h-[15px] flex items-center justify-center bg-slate-300 rounded-[4px] text-black font-bold text-[10px]'
                    onClick={e => {
                      e.stopPropagation();
                      setShowCheckModal(true);
                  }}>{t("pika.Check")}</button> }
                </>
              ) : (
                <div className='w-6 h-6 rounded-full bg-[#23FFF7] flex items-center justify-center'>
                  <img
                    alt='LIKE_RT_BBY_SUMMIT_1'
                    loading='lazy'
                    width='15'
                    height='15'
                    decoding='async'
                    data-nimg='1'
                    srcSet='/right.svg 1x, /right.svg 2x'
                    src='/right.svg'
                    style={{ color: 'transparent' }}
                  />
                </div>
              )}
            </div>
          ) : (
            <span className='font-semibold bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-transparent bg-clip-text'>
              {t("pika.Completed")}
            </span>
          )}
        </li>
      )}
      {showCheckModal &&
        createPortal(
          <Content
            title={en}
            loading={false}
            taskId={id}
            onClose={() => setShowCheckModal(false)}
            onChange={onChange}
          />,
          document.body
        )}
    </>
  )
}
