import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function AutoScrollTop({ children }) {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [location.pathname]);

  return children;
}