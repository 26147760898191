export default {
    "1": {
        "id": 1,
        "type": 1,
        "en": "farm 1 times today",
        "cn": "今天耕种 1 次",
        "progressMax": 40,
        "link": "{\"page\":3,\"tab\":1,\"btn\":1,\"id\":201}",
        "conditionType": "ploughing",
        "conditionValue": 1,
        "turnonReward": 20
    },
    "2": {
        "id": 2,
        "type": 1,
        "en": "farm 2 times today",
        "cn": "今天耕种2 次",
        "progressMax": 40,
        "link": "{\"page\":3,\"tab\":1,\"btn\":1,\"id\":202}",
        "conditionType": "ploughing",
        "conditionValue": 2,
        "turnonReward": 30
    },
    "3": {
        "id": 3,
        "type": 2,
        "en": " invite 1 new user",
        "cn": "邀请 1 位新用户",
        "progressMax": 40,
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":203}",
        "conditionType": "invite",
        "conditionValue": 1,
        "turnonReward": 40
    },
    "4": {
        "id": 4,
        "type": 1,
        "en": "farm 3 times today",
        "cn": "今天耕种3 次",
        "progressMax": 40,
        "link": "{\"page\":3,\"tab\":1,\"btn\":1,\"id\":204}",
        "conditionType": "ploughing",
        "conditionValue": 3,
        "turnonReward": 50
    },
    "5": {
        "id": 5,
        "type": 1,
        "en": "farm 4 times today",
        "cn": "今天耕种4 次",
        "progressMax": 40,
        "link": "{\"page\":3,\"tab\":1,\"btn\":1,\"id\":206}",
        "conditionType": "ploughing",
        "conditionValue": 4,
        "turnonReward": 80
    },
    "6": {
        "id": 6,
        "type": 2,
        "en": " invite 2 new user",
        "cn": "邀请 2 位新用户",
        "progressMax": 40,
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":207}",
        "conditionType": "invite",
        "conditionValue": 2,
        "turnonReward": 100
    },
    "7": {
        "id": 7,
        "type": 2,
        "en": " invite 4 new user",
        "cn": "邀请 4 位新用户",
        "progressMax": 40,
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":207}",
        "conditionType": "invite",
        "conditionValue": 4,
        "turnonReward": 150
    }
}