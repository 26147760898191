import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { useNavigate } from 'react-router-dom'
import RightArrow from '../me/svg/RightArrow';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function Settings() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ currentLang, setCurrentLang ] = useState("");
  const lngOptions = useSelector(state => state.assets.lngOptions);

  useEffect(() => {
    setCurrentLang(localStorage.getItem("i18nextLng"))
  }, [])

  return (
    <div
      className="min-h-screen select-none text-white p-4"
      style={{
        background: `linear-gradient(0deg, #000000, #000000), linear-gradient(360deg, #434343 0%, #000000 100%)`,
      }}
    >
      <Header onClick={() => navigate("/me")} title="Settings" />
      <div className='flex items-center justify-between bg-[#141414] py-2.5 px-[15px] rounded-[7px]'>
        <p className='text-[#FFFFFFB2]'>{t("Language")}</p>
        <p className='flex items-center' onClick={() => navigate("/lang")}>
          <span className='pr-1 cursor-pointer'>{lngOptions.find(item => item.value === currentLang)?.label}</span>
          <RightArrow />
        </p>
      </div>
    </div>
  )
}
