export function createShakeAni(domId, isAdd = true) {
  const dom = document.getElementById(domId);
  if (!dom) return;
  function endFn(event) {
    dom.classList.remove("shake-horizontal");
    dom.removeEventListener("animationend", endFn);
  }
  if (isAdd) {
    dom.classList.add("shake-horizontal");
    dom.addEventListener("animationend", endFn);
  } else {
    dom.classList.remove("shake-horizontal");
    dom.removeEventListener("animationend", endFn);
  }
}
