import { getHttpMock } from "../../utils";
import { httpPost } from "../../utils/httpRequest";
import { getMockTaskData } from "./mock";

/**
 * 获取任务列表信息
 * @returns
 * @see https://candy-porpoise-601.notion.site/abf82d006cce4f6a8abe3ee9ff51b51e
 */
export async function queryTaskInfos() {
  const reqData = await httpPost("/v1/farming/getTasksInfos");
  return reqData?.taskDatas;
  // return getMockTaskData();
}

/**
 * 前端跳转通知任务
 * @returns
 * @see https://candy-porpoise-601.notion.site/f611d6dfa986404ebdc3da1dfcaaf6ea
 */
export async function noticeTaskInfo(taskId) {
  httpPost("/v1/farming/noticeTask", { taskId });
  // return getMockTaskData();
}

/**
 * 排行榜信息
 * @returns
 * @see https://candy-porpoise-601.notion.site/abf82d006cce4f6a8abe3ee9ff51b51e
 */

export async function queryRankInfo(params) {
  const reqData = httpPost("/v1/farming/getFramerRanks", params);
  return reqData;
}

/**
 * 绑定钱包通知接口
 * @returns
 * @see https://candy-porpoise-601.notion.site/d55b0c6440ac4dd28d06b7312a1281fe
 */

export async function bindWallet(params) {
  const reqData = httpPost("/v1/farming/bindWallet", params);
  return reqData;
}

/**
 * check task
 */

export async function checkTask(taskId) {
  const reqData = httpPost("/v1/farming/checkTask", { taskId });
  return reqData;
}
