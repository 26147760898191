import RankItem from './RankItem'

export default function RankList() {
  const list = [
    {
      rankNumber: 1,
      userName: "Alsdjkdhgak"
    },
    {
      rankNumber: 2,
      userName: "Alsdjkdhgak"
    },
    {
      rankNumber: 3,
      userName: "Alsdjkdhgak"
    },
    {
      rankNumber: 4,
      userName: "Alsdjkdhgak"
    },
    {
      rankNumber: 5,
      userName: "Alsdjkdhgak"
    },
    {
      rankNumber: 6,
      userName: "Alsdjkdhgak"
    }
  ]
  return (
    <>
      <div className='mt-6 flex items-center justify-between font-bold mb-3'>
        <div className='flex items-center'>
          <img width={15} height={15} src='/reward2.png' alt='' />
          <p
            className='ml-2'
            style={{
              background: 'linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Top 30 Monthly Stars
          </p>
        </div>
        <p className='text-sm text-[#FFFFFF99]'>Rules</p>
      </div>
      <ul className='h-[60vh] overflow-auto pb-28'>
        {list.map((item, index) => (
          <RankItem key={index} data={item} />
        ))}
      </ul>
    </>
  )
}
