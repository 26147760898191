import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setJumpChain } from "../store/features/commonSlice";
import { useEffect, useState } from "react";

const pageDic = {
  1: "/game",
  2: "/task",
  3: "/",
  4: "/friends",
  5: "/me",
};

//--------------------用法------------------------------//
// 触发页
// dispatch(setJumpChain({ page: "2", tab: "rank", btn: "", link: "", id: "11" }));

// 响应页
// useJumpChain({
//   11: {
//     tabFn: () => {
//       setCurTab("rank");
//       getRankList();
//     },
//   },
//   22: {
//     tabFn: () => {
//       setCurTab("rank");
//       getRankList();
//     },
//   },
// });
//--------------------用法------------------------------//

/**
 * 跳到页面的某一处或者外部链接 ，一个页面可能对应多过跳转处理。页面需要在useJumpChain 钩子中注册对应的处理方法；！！！不要用 await ，因为两秒后就会清空！！！
 * @param {*} customizeFun 自定义函数
 */
export async function useJumpChain(fnCfg) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [init, setInit] = useState(false);
  // 一个页面可能需要相应多个跳转，这里用 id去对应他们的处理方法
  const { page, tab, btn, link, hasCustomize, id } = useSelector(
    (state) => state?.common?.jumpChain || {}
  );

  // 通过 id 取出不同的处理方法
  const { tabFn, btnFn, customizeFun } = fnCfg?.[id] || {};

  useEffect(() => {
    setTimeout(() => {
      setInit(true);
    }, 300);
    return () => {
      setInit(false);
    };
  }, []);

  useEffect(() => {
    if (init) {
      if (link) {
        // 外部跳转
        dispatch(setJumpChain({ link: "", page, tab, btn, hasCustomize }));
        window.open(link);
      } else if (page) {
        const targetPage = pageDic[page];
        if (pathname !== targetPage && targetPage) {
          // 页面跳转
          setTimeout(() => {
            navigate(targetPage);
          }, 300);
          return;
        }
        if (pathname === targetPage) {
          dispatch(
            setJumpChain({
              page: "",
              tab,
              btn,
              link,
              hasCustomize,
            })
          );
        }
        if (tab && tabFn) {
          tabFn?.();
          dispatch(
            setJumpChain({
              page: "",
              tab: "",
              btn,
              link: "",
              hasCustomize,
            })
          );
          return;
        }
        if (btn && btnFn) {
          btnFn?.();
          dispatch(
            setJumpChain({
              page: "",
              tab,
              btn: "",
              link: "",
              hasCustomize,
            })
          );
          return;
        }
        if (hasCustomize && hasCustomize) {
          customizeFun?.();
          dispatch(
            setJumpChain({
              page: "",
              tab,
              btn,
              link: "",
              hasCustomize: "",
            })
          );
          return;
        }
      }
    }
  }, [init, page, tab, btn, link, hasCustomize, id, pathname]);
}
