import RankList from './components/RankList'
import VerifyCard from './components/VerifyCard'

export default function PikaBot({ onClick }) {
  return (
    <div>
      <div
        className='text-[16px] font-bold'
        style={{
          background: 'linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          textFillColor: 'transparent',
          WebkitTextFillColor: 'transparent',
        }}
      >
        My Community
      </div>
      <VerifyCard onClick={onClick} />
      <RankList />
    </div>
  )
}
