import { useEffect, useState } from "react";
import ClockSvg from "../../../components/svg/ClockSvg";
import { useTranslation } from "react-i18next";

export default function DescriptionInfo() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [ currentLang, setCurrentLang ] = useState("")

  function onShowDetail() {
    setOpen(!open);
  }

  useEffect(() => {
    setCurrentLang(localStorage.getItem("i18nextLng"));
  }, [])

  return (
    <div className={`rounded-xl bg-[rgba(0, 0, 0, 0.4)] ${open ? "mb-6" : ""}`}>
      <div
        onClick={onShowDetail}
        className="mt-4 flex items-center justify-center text-smtext-center text-[19px] font-[800] leading-[2rem] bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] bg-clip-text text-transparent"
      >
        {t("pika.Invite Friends & Get Rewards")}
      </div>
      {open ? (
        <>
          <div className="flex w-[100%] justify-center rotate-180 cursor-pointer" onClick={() => setOpen(false)}>
            <img src="/rank_detail_show.svg" />
          </div>
          <div
            className={`mt-2 p-4 flex-row items-center rounded-lg`}
            style={{
              background: "rgba(255, 255, 255, 0.08)",
            }}
          >
            <div className="font-bold">
              🎁{" "}
              <span className="bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] bg-clip-text text-transparent">
                {t("pika.Regular Rewards")}
              </span>
            </div>
            {
              currentLang === "en" ? (
                <>
                  <div className="py-3 first:pt-0 last:pb-0 text-white text-sm">
                    Invite a friend,{" "}
                    <span className="text-[#17FFF7] font-[700]">+100 PIKA</span> for
                    you and{" "}
                    <span className="text-[#17FFF7] font-[700]">+50 PIKA</span> for
                    your friend.
                  </div>
                  <div className="py-3 first:pt-0 last:pb-0 text-white text-sm">
                    Invite a friend with Telegram Premium,{" "}
                    <span className="text-[#17FFF7] font-[700]">+500 PIKA</span> for
                    you and{" "}
                    <span className="text-[#17FFF7] font-[700]">+250 PIKA</span> for
                    your friend.
                  </div>
                </>
              ) : (
                <>
                  <div className="py-3 first:pt-0 last:pb-0 text-white text-sm">
                    {t("pika.regular_rewards_desc1")}
                  </div>
                  <div className="first:pt-0 last:pb-0 text-white text-sm">
                    {t("pika.regular_rewards_desc2")}
                  </div>
                </>
              )
            }
          </div>
          {/* <div
            className="mt-1 p-4 flex-row items-center rounded-lg"
            style={{
              background: "rgba(255, 255, 255, 0.08)",
            }}
          >
            <div className="font-bold bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] bg-clip-text text-transparent">
              Season Duration
            </div>
            <span className="py-3 first:pt-0 last:pb-0 text-sm font-medium text-white flex items-center justify-center space-x-1">
              <ClockSvg />
              <span>30 August 12PM – 30 September 12PM (UTC+8)</span>
            </span>
          </div>
          <div
            className="mt-1 p-4 flex-row items-center rounded-lg text-white"
            style={{
              background: "rgba(255, 255, 255, 0.08)",
            }}
          >
            <div className="font-bold">
              🎁{" "}
              <span className="bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] bg-clip-text text-transparent">
                Bonus
              </span>
            </div>
            <div className="py-2 first:pt-0 last:pb-0 text-white text-sm">
              Inviting <span className="text-[#17FFF7] font-[700]">150</span>{" "}
              new users, get extra{" "}
              <span className="text-[#17FFF7] font-[700]">5 USDT</span>.
            </div>
            <div className="py-2 first:pt-0 last:pb-0 text-white text-sm">
              Inviting <span className="text-[#17FFF7] font-[700]">1500</span>{" "}
              new users, get extra{" "}
              <span className="text-[#17FFF7] font-[700]">50 USDT</span>.
            </div>
            <div
              className="py-2 first:pt-0 last:pb-0 text-white text-sm"
              style={{ fontWeight: "400" }}
            >
              Inviting <span className="text-[#17FFF7] font-[700]">3000</span>{" "}
              new users, get extra{" "}
              <span className="text-[#17FFF7] font-[700]">100 USDT</span>.
            </div>
            <div className="py-2 first:pt-0 last:pb-0 text-white text-sm">
              Inviting <span className="text-[#17FFF7] font-[700]">6000</span>{" "}
              new users, get extra{" "}
              <span className="text-[#17FFF7] font-[700]">200 USDT</span>.
            </div>
            <div
              className="py-3 first:pt-0 last:pb-0 text-white"
              style={{ fontWeight: "400" }}
            >
              <div className="mb-0.5 flex items-center text-[0.875rem]">
                ⚠️
                <div
                  className="ml-0.5 font-[700]"
                  style={{
                    background:
                      "linear-gradient(rgb(118, 111, 250) 0%, rgb(147, 111, 250) 0.01%, rgb(0, 0, 0) 100%) text",
                    Webkit: "transparent",
                  }}
                >
                  Important note
                </div>
              </div>
              Only Bonus must be completed within the season duration to receive
              rewards.
              <div>Regular rewards are always effective.</div>
            </div>
          </div> */}
        </>
      ) : (
        <div className="flex w-[100%] justify-center cursor-pointer" onClick={onShowDetail}>
          <img src="/rank_detail_show.svg" />
        </div>
      )}
    </div>
  );
}
