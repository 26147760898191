import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { queryUserAssetsInfo } from "../../api/assets";

const initialState = {
  assetsInfo: {},
  loading: true,
  usdtInfo: {
    index: 0,
    usdt: 10,
    text: ""
  },
  lngOptions: [
    { label: 'English', value: 'en' },
    // { label: "Français", value: "fr" },
    // { label: "Deutsch", value: "de" },
    { label: 'Chinese,Simplified (简体中文)', value: 'zh-CN' },
    { label: 'Chinese,Traditional (繁體中文)', value: 'zh-TW' },
  ]
};

export const fetchAssets = createAsyncThunk(
  'assets/fetchAssets',
  async () => {
    try {
      const res = await queryUserAssetsInfo();
      return res;
    } catch (error) {
      console.log(error)
    }
  }
)

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setAssetsInfo: (state, action) => {
      state.assetsInfo = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setUsdtInfo: (state, action) => {
      state.usdtInfo = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.assetsInfo = action.payload
        state.loading = false
      })
      .addCase(fetchAssets.rejected, (state, err) => {
        console.log('assetsError', err)
        state.loading = false
      })
  }
});

export const { setAssetsInfo, setUsdtInfo } = assetsSlice.actions;

// 默认导出
export default assetsSlice.reducer;