import { thousandEnNum } from "../../../../utils";

function PointBar(props) {
  const { value } = props;
  return (
    <div className="flex min-w-[63px] items-center justify-center border border-[#FFFFFF1A] rounded-[10px] h-[23px] px[10px] py-[6px]">
      <img className="w-[8px] h-[11px] mr-[2px]" src="/user_point_icon.svg" />
      <span className="text-sm text-white font-semibold">
        {thousandEnNum(value)}
      </span>
    </div>
  );
}

export default PointBar;
