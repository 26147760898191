import { useTranslation } from "react-i18next";
import { showRankListIconModal } from "../../modal/RankListIconModal";
import { showSubordinateModal } from "../../modal/SubordinateModal";
import RankItem from "./RankItem";

export default function RankList(props) {
  const { t } = useTranslation();
  const { data } = props || {};

  const onClick = () => {
    showRankListIconModal();
  };

  function onShowSubordinate(itemData) {
    showSubordinateModal(itemData);
  }

  return (
    <>
      <div className="mt-2 flex flex-row px-2.5" id="user-row">
        <div className="w-8 text-center text-sm text-[#FFFFFF99]">#</div>
        <div className="flex-grow text-sm text-[#FFFFFF99]">{t("pika.User")}</div>
        <div
          className="w-30 text-sm text-[#FFFFFF99] flex items-center cursor-pointer"
          onClick={onClick}
        >
          {t("pika.Value")}
          {/* ⭐ Total */}
          <img
            className="w-[18px] h-[18px] ml-[2px]"
            src="/friend-rank-icon.svg"
            alt="rank_list_icon"
          />
        </div>
      </div>
      <ul className="pb-28 flex flex-col space-y-2 mt-2 text-white">
        {data?.map((item) => (
          <RankItem
            key={item.rankNumber}
            data={item}
            onClick={() => {
              onShowSubordinate(item);
            }}
          />
        ))}
      </ul>
    </>
  );
}
