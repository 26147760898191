import { useState } from 'react'
import CopySvg from '../../../../components/svg/CopySvg2'
import ErrorSvg from '../../../../components/svg/ErrorSvg'
import BackSvg from '../../../../components/svg/BackSvg'
import Button from '../../../../components/Button'
import copy from 'copy-to-clipboard'
import { showToast } from '../../../../components/showToast'
import { COMMON_TOAST_TYPE_SUCCESS } from '../../../../components/showToast/constance'
import { useDispatch, useSelector } from 'react-redux'
import Title from '../../../../components/Title'
import {
  setVerifyPage,
  setCheckState,
} from '../../../../store/features/userSlice'
import { BOT_USERNAME } from "../../../../config"

export default function Verify() {
  const [value, setValue] = useState('t.me/')
  const tgName = useSelector((state) => state.common.tgName)
  const dispatch = useDispatch();
  const [state, setState] = useState(true);

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onCopy = () => {
    console.log(tgName)
    copy(`@${BOT_USERNAME}`)
    showToast('Copy success', COMMON_TOAST_TYPE_SUCCESS, 1)
  }

  const handleBack = () => {
    dispatch(setVerifyPage(false))
    dispatch(setCheckState(true))
  }

  const onCheck = () => {
    console.log(111)
    // setState(false)
    if (state) {
      dispatch(setCheckState(true))
      dispatch(setVerifyPage(false))
    }
  }

  return (
    <div className='h-full overflow-auto pb-28'>
      <Title title='Verify Your Community' />
      <div className='mt-7 font-medium text-white text-base'>
        <div className='flex items-center justify-between'>
          <div>
            <p>1. Add the PIKA Verifier bot as anadmin</p>
            <p className='text-[#FFFFFFB2]'>@{BOT_USERNAME}</p>
          </div>
          <div onClick={onCopy}>
            <CopySvg />
          </div>
        </div>
        <div className='mt-7'>
          <p className='mb-2'>
            2. Check and Claim Your FREE Community Owner Rewards
          </p>
          <input
            data-slot='input'
            data-has-start-content='true'
            className='h-10 w-full bg-[#FFFFFF1A] rounded-[22px] px-[15px] outline-[#243c5a]'
            value={value}
            type='text'
            placeholder='t.me/'
            onChange={onChange}
          />
        </div>
        {!state && (
          <div className='flex items-center mt-2 text-sm text-[#EF4444] font-medium'>
            <ErrorSvg />
            <p className='pl-2'>Failed to verify, please check and try again</p>
          </div>
        )}
        <p className='text-[#FFFFFFB2] font-[15px] mt-2'>
          The link for Telegram community or channel where you're the{' '}
          <span className='text-white font-bold'>owner</span>.
        </p>
        <div className='mt-20'>
          <p className='text-[#FFFFFFB2] text-[15px] font-normal'>
            Level upgrade requirements may differ based onyour role within the
            verified community.
          </p>
          <div className='mt-4 flex items-center'>
            <div onClick={handleBack}>
              <BackSvg />
            </div>
            <Button text='Check' margin='10px' onClick={onCheck} />
          </div>
        </div>
      </div>
    </div>
  )
}
