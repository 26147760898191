import { useEffect, useState } from "react";
import CPlayTasksTemplates from "../../constants/CPlayTasksTemplates";
import { GUIDE_MODAL_KEY, TASK_ID } from "./constance";
import ModalContent from "./Content";
import { checkTaskCompleted } from "./biz";
import classNames from "classnames";
import GuideCheckModal from "./CheckModal";
import { queryVisitorLogin } from "../../api/users";
import PikaStepsTemplates from "../../constants/PikaStepsTemplates";
import { BOT_USERNAME, PLATFORM } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import { URL_GUIDE_KEY } from "../../constants";
import { httpPost } from "../../utils/httpRequest";
import { getPikaInfos } from "../../api/commom";
import { useDispatch } from "react-redux";
import { setCommonInfo, setIsLogin } from "../../store/features/commonSlice";
import { checkShowGuideView, seedGuideData } from "../../utils";
import { showInviteFriendsModal } from "../InviteFriendsModal";

function initData() {
  let stepsData = {};
  for (let k in PikaStepsTemplates) {
    const val = { ...PikaStepsTemplates[k] };
    stepsData[k] = val;
  }
  return stepsData;
}

function GuideModal() {
  const [stepInfo, setStepInfo] = useState(initData);
  const [isCompleted, setIsCompleted] = useState(false);
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [hadInit, setHadInit] = useState(false);
  // const [bgstyle, setBgstyle] = useState("bg-cover");
  const [showNormalBg, setShowNormalBg] = useState(false);
  const [winH, setWinH] = useState(700);

  async function login() {
    const loginData = {
      platform: PLATFORM,
      tgWebAppData: window.Telegram?.WebApp?.initData,
      botUsername: BOT_USERNAME,
    };
    await httpPost("/v1/users/login", loginData, { cacheToken: true });
    dispatch(setIsLogin(true));
    const resp = await getPikaInfos();
    dispatch(setCommonInfo(resp));
  }

  async function getPageInfo() {
    try {
      const resp = await queryVisitorLogin({
        platform: PLATFORM,
        tgWebAppData: window?.Telegram?.WebApp?.initData,
        botUsername: BOT_USERNAME,
      });
      setHadInit(true);
      const { steps } = resp || {};
      let stepsData = {};
      let completedCount = 0;
      for (let k in stepInfo) {
        const val = stepInfo[k];
        val.isCompleted = steps[k];
        if (steps[k]) {
          completedCount += 1;
        }
        stepsData[k] = val;
      }
      setStepInfo(stepsData);
      const isDone = completedCount >= 2;
      setIsCompleted(isDone);

      if (isDone) {
        setTimeout(() => {
          showInviteFriendsModal();
        }, 1000);
        login();
      } else {
        showInviteFriendsModal({ showInGuide: true });
      }
    } catch (err) {
      setTimeout(() => {
        showInviteFriendsModal();
      }, 1000);
    }
  }

  async function onNext() {
    setIsCompleted(true);
    try {
      await login();
      // 埋点6 - 进入Pika主页面
      seedGuideData(6);
      setTimeout(() => {
        showInviteFriendsModal();
      }, 1000);
      navigator("/friends");
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getPageInfo();
    const onResize = () => {
      const h = window.innerHeight;
      setWinH(h);
    };

    setTimeout(() => {
      onResize();
      window.addEventListener("resize", onResize);
    }, 500);
    setTimeout(() => {
      setShowNormalBg(true);
      onResize();
    }, 1000);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const bgstyle = winH >= 700 ? "bg-contain" : "bg-cover";

  return (
    <>
      {!isCompleted && hadInit && checkShowGuideView() && (
        <div
          className={classNames(
            `fixed z-[999] top-0 left-0 w-full h-full ${bgstyle} bg-no-repeat bg-[#000000]`,
            isCompleted ? "pointer-events-none" : "pointer-events-auto"
          )}
          style={{
            backgroundImage: `url(${
              showNormalBg ? "/guide-bg.jpg" : "/guide-bg-init.jpg"
            })`,
          }}
        >
          <div className="flex w-full h-full flex-col items-center">
            <ModalContent
              data={stepInfo}
              winH={winH}
              onCompleted={onNext}
              onChange={(data) => {
                const tempStepsInfo = { ...stepInfo };
                for (let k in data) {
                  tempStepsInfo[k].isCompleted = data[k];
                }
                setStepInfo(tempStepsInfo);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default GuideModal;
