import IconBar from "./IconBar";
import PointBar from "./PointBar";

export default function RankItem(props) {
  const { data, onClick } = props || {};
  const { rankNumber, tgName, headId, invateAmount } = data || {};

  return (
    <li
      className="mt-2 flex flex-col items-center shadow-lg rounded-xl p-2.5 text-white bg-[#36363680] w-[100%]"
      onClick={onClick}
    >
      <div className="flex items-center justify-between w-[100%]">
        {/* {data && rankNumber <= 3 ? (
          rankNumber === 1 ? (
            <img className="w-[20px] mr-2" src="/first.png" alt="No.1" />
          ) : data.rankNumber === 2 ? (
            <img className="w-[20px] mr-2" src="/second.png" alt="No.2" />
          ) : (
            <img className="w-[20px] mr-2" src="/third.png" alt="No.3" />
          )
        ) : (
          <span className="block w-6 h-6 mr-2 text-center">{rankNumber}</span>
        )} */}
        <div className="flex flex-grow flex-row items-center">
          <div
            data-loaded="true"
            className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[39px]"
          >
            <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
              <div
                className="relative inline-flex"
                style={{ width: "39px", paddingTop: "3px" }}
              >
                <div style={{ marginLeft: "2px", marginTop: "2px" }}>
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                      position: "relative",
                      width: "35px",
                      height: "35px",
                      overflow: "hidden",
                      backgroundColor: "transparent",
                      zIndex: "10",
                    }}
                  >
                    <img src="/pika-avatar.jpg" alt="avatar" />
                  </div>
                </div>
                <div
                  style={{
                    clipPath:
                      "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                    position: "absolute",
                    width: "39px",
                    height: "39px",
                    overflow: "hidden",
                    // backgroundColor: 'linear-gradient(180deg, #00E0FF 0%, #00FFB3 39.5%, #000000 100%)',
                    backgroundColor: "rgba(255,255,255,0.1)",
                    zIndex: "5",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="ml-2 text-sm font-bold">{tgName}</div>
        </div>
        {/* <div className="w-30 text-sm  text-white">{invateAmount}</div> */}
        <PointBar value={invateAmount} />
      </div>
      <IconBar data={data} />
    </li>
  );
}
