import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useCountDown } from "ahooks";
import dayjs from "dayjs";
import { END_DATE } from "./constance";
import TimeBar from "./components/TimeBar";
import { useJumpChain } from "../../hooks/useJumpChain";
import { useDispatch } from "react-redux";
import { setJumpChain } from "../../store/features/commonSlice";

function InnerModalContent(props) {
  const { needJump = true } = props;
  const modal = useModal();
  const dispatch = useDispatch();

  useJumpChain();

  function onTimeEnd() {}

  const [countdown, formattedRes] = useCountDown({
    targetDate: dayjs(END_DATE),
    onEnd: onTimeEnd,
  });

  const { days, hours, minutes, seconds } = formattedRes;

  function onClose() {
    modal.remove();
  }

  function onClick() {
    onClose();
    if (needJump) {
      dispatch(setJumpChain({ page: 4, btn: 1, id: 999 }));
    }
  }

  return (
    <div className="fixed w-full h-full z-[9999] top-0 left-0 bg-[#000000BF]">
      <div
        className="w-full h-full flex items-center justify-center"
        onClick={onClick}
      >
        <div
          className="w-[330px] h-[420px] relative"
          style={{
            backgroundImage: "url('/invite_friend_bg.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            objectFit: "cover",
          }}
        >
          <img
            className="w-[16px] h-[16px] absolute z-40 right-[25px] top-[25px]"
            onClick={(e) => {
              e.stopPropagation();
              onClose()
            }}
            src="/comom-close.png"
          />
          <div className="w-full flex justify-center pt-[63px]">
            <TimeBar list={[days, hours, minutes]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NiceModal.create(InnerModalContent);
