import { useTranslation } from "react-i18next"
import { useRippleEff } from "../../../hooks/useRippleEff"

export default function WithdrawBtn({ onClick, disabled }) {
  useRippleEff()
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="w-full rounded-lg py-3.5 text-black font-[700] text-[17px] ripple overflow-hidden"
      style={{
        // background: `linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)`,
        background: disabled ? '#aaa' : `linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)`,
        filter: disabled ? 'grayscale(100%)' : 'grayscale(0)'
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {t("pika.Withdraw")}
    </button>
  )
}