import { useEffect, useRef } from 'react'
import VConsole from 'vconsole'
import { SettingSvg } from '../svg/SettingSvg'
import { useNavigate } from 'react-router-dom'

function PageHead({ tgName }) {
  const coiledCount = useRef(0)
  const lastClickTime = useRef(0)
  const navigate = useNavigate()

  const onClick = () => {
    console.log(1111)
    const time = new Date().getTime()
    if (time - lastClickTime.current <= 200) {
      coiledCount.current += 1
    } else {
      coiledCount.current = 0
    }
    if (coiledCount.current === 5) {
      const vConsoleOpen = Number(localStorage.getItem('vConsoleOpen'))
      if (!vConsoleOpen) {
        window.vConsole = new VConsole()
      } else {
        if (window.vConsole) {
          window.vConsole.destroy()
        }
      }
      localStorage.setItem('vConsoleOpen', vConsoleOpen ? '0' : '1')
    } else if (coiledCount.current === 2) {
      // localStorage.removeItem("i18nextLng");
      localStorage.removeItem("i18next_res_en-translation");
      localStorage.removeItem("i18next_res_zh-translation");
      localStorage.removeItem("i18next_res_zh-TW-translation");
    }
    lastClickTime.current = time
  }

  return (
    <div className='relative'>
      <div className='flex flex-col items-center justify-center'>
        <div
          data-loaded='true'
          className='group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none w-[76px]'
        >
          <div className='opacity-0 group-data-[loaded=true]:opacity-100 transition-none'>
            <div
              className='relative inline-flex'
              style={{ width: '76px', paddingTop: '3px' }}
            >
              <div style={{ marginLeft: '2px', marginTop: '2px' }}>
                <div
                  style={{
                    clipPath:
                      'polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)',
                    position: 'relative',
                    width: '72px',
                    height: '72px',
                    overflow: 'hidden',
                    zIndex: '10',
                  }}
                >
                  <img
                    alt='avatar'
                    loading='lazy'
                    width='72'
                    height='72'
                    decoding='async'
                    data-nimg='1'
                    srcSet='/pika-avatar.jpg 1x, /pika-avatar.jpg 2x'
                    src='/pika-avatar.jpg'
                    style={{
                      color: 'transparent',
                      position: 'absolute',
                      inset: '0px',
                    }}
                    onClick={onClick}
                  />
                </div>
              </div>
              <div
                style={{
                  clipPath:
                    'polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)',
                  position: 'absolute',
                  width: '76px',
                  height: '76px',
                  overflow: 'hidden',
                  background:
                    'linear-gradient(180deg, #00E0FF 0%, #00FFB3 39.5%, #000000 100%)',
                  zIndex: '5',
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className='text-[20px] font-bold'>{tgName}</div>
      </div>
      <div className="absolute top-0 right-0 cursor-pointer" onClick={() => navigate("/settings")}>
        <SettingSvg />
      </div>
    </div>
  )
}

export default PageHead
