import TaskItem from "./TaskItem";
import Title from "./Title";

function TaskItemsBlock() {
  return (
    <div className="mt-2 rounded-lg bg-white px-2 py-1 bg-gradient-to-r from-[#FFEFD1] to-[#FFF]">
      <Title />
      <div className="px-1 py-1" id="content">
        <TaskItem />
        <TaskItem />
      </div>
    </div>
  );
}

export default TaskItemsBlock;
