/**
 * 从本地获取宝箱的敲击数据 返回一个这样的对象{tapCount:0,lastTapTime:0}
 * tapCount: 0, //连续敲击次数
 * lastTapTime: 0, 上一次敲击的时间戳
 */
export function getBoxTapInfoById(boxId) {
  const boxsTapTimeDic = JSON.parse(
    sessionStorage.getItem("boxsTapInfo") || "{}"
  );
  const tempData = boxsTapTimeDic[boxId] || {};
  return {
    tapCount: Number(tempData?.tapCount) || 0,
    lastTapTime: Number(tempData?.lastTapTime) || 0,
  };
}

/**
 * 把敲击数据放到本地
 * @param {*} boxId 宝箱的 id
 * @param {*} data  {tapCount:0,lastTapTime:0}
 */
export function setBoxTapInfoById(boxId, data) {
  const boxsTapTimeDic = JSON.parse(
    sessionStorage.getItem("boxsTapInfo") || "{}"
  );
  boxsTapTimeDic[boxId] = data;

  const dataStr = JSON.stringify(boxsTapTimeDic);

  sessionStorage.setItem("boxsTapInfo", dataStr);
}
