import { useNavigate } from "react-router-dom";
import SwiperBoxes from "./components/SwiperBoxes";
import DailyBonus from "./components/DailyBonus";
import LineInfo from "./components/LineInfo";
import TapEarn from "./components/TapEarn";
import MyPoints from "./components/MyPoints";
import { useEffect, useRef, useState } from "react";
import {
  checkBox,
  getFarmerInfos,
  getTapOreBoxInfos,
  tapBox,
} from "../../api/box/index";
import HeadTab from "./components/HeadTab";
import { useDebounceFn, useInterval } from "ahooks";
import { numCalc } from "../../utils/num";
// import Loading from "../../components/Loading/Loading";
import "./index.css";
import { useDispatch } from "react-redux";
import { useJumpChain } from "../../hooks/useJumpChain";
import { createShakeAni } from "../../utils/cssAni";
import { showToast } from "../../components/showToast";
import { COMMON_TOAST_TYPE_WARNNING } from "../../components/showToast/constance";
import { useTranslation } from "react-i18next";

const linkKeys = [201, 202, 204, 206, 102, 103];

const getLinkData = () => {
  const result = {};
  linkKeys.forEach((item) => {
    result[item] = {
      btnFn: () => {
        createShakeAni("earn-btn");
      },
    };
  });
  return result;
};

export default function GamePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [pageBoxInfo, setPageBoxInfo] = useState({});
  const [pointInfo, setPointInfo] = useState({});
  const loopTime = useRef(0);

  const dispatch = useDispatch();
  useJumpChain(getLinkData());

  async function getBoxsInfo() {
    try {
      const resp = await getTapOreBoxInfos();
      setPageBoxInfo(resp);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  async function getPointInfo() {
    try {
      const resp = await getFarmerInfos();
      setPointInfo(resp);
    } catch (err) {
      console.log(err);
    }
  }

  function createAddScoreTxt() {
    const parentDom = document.getElementById("home_tap");
    const dom = document.createElement("span");
    const desc = document.createTextNode("+1");
    dom.className = "gamePageAddScoreTxt";
    dom.appendChild(desc);
    parentDom.appendChild(dom);

    const aniEndFn = () => {
      parentDom.removeChild(dom);
      dom.removeEventListener("animationend", aniEndFn);
    };

    dom.addEventListener("animationend", aniEndFn);
  }

  async function boxClick(no) {
    // tapBox(no);
    createAddScoreTxt();
    const boxs = pageBoxInfo?.boxs;
    boxs[no] = {
      ...boxs[no],
      progress: numCalc(Number(boxs[no].progress), "+", 1),
    };
    let oreRemainAmount = numCalc(Number(pageBoxInfo?.oreRemainAmount), "-", 1);
    if (oreRemainAmount <= 0) {
      oreRemainAmount = 0;
    }
    // 5秒后更新数据
    loopTime.current = 5;
    setPageBoxInfo({ ...pageBoxInfo, boxs, oreRemainAmount });
  }

  async function onOpen(no) {
    const resp = await checkBox(no);
    getBoxsInfo();
  }

  useEffect(() => {
    getPointInfo();
    getBoxsInfo();
  }, []);

  useInterval(() => {
    const tempData = { ...pageBoxInfo };
    const notMax =
      Number(tempData.oreRemainAmount) < Number(tempData.oreAmountMax);
    if (tempData.oreRemainAmount && tempData.speed && notMax) {
      tempData.oreRemainAmount = numCalc(
        Number(tempData?.oreRemainAmount),
        "+",
        Number(tempData.speed)
      );
      setPageBoxInfo(tempData);
    }
    loopTime.current += 1;
    if (loopTime.current % 10 === 0) {
      getPointInfo();
      getBoxsInfo();
    }
  }, 1000);

  return (
    <div
      className="round-lg select-none overflow-auto mx-auto flex h-screen max-w-3xl flex-col bg-cover bg-center bg-no-repeat pb-28"
      id="home_tap"
    >
      <>
        <div className="px-4 pt-2">
          <HeadTab />
          <MyPoints
            data={pointInfo}
            onClaim={getPointInfo}
            onTimeEnd={getPointInfo}
          />
          <div id="top" className="mt-3">
            <DailyBonus data={pageBoxInfo} />
          </div>
        </div>
        <div className="mt-1 flex-1">
          <LineInfo data={pageBoxInfo} />
          <SwiperBoxes
            isLoading={loading}
            data={pageBoxInfo}
            onClick={boxClick}
            onOpen={onOpen}
          />
        </div>
      </>
    </div>
  );
}
