export default {
    "1": {
        "id": 1,
        "type": 1,
        "en": "Daily login",
        "cn": "每日登录",
        "avatar": "icon-DailyLogin.svg",
        "link": 1,
        "noticeServer": 1,
        "conditionType": "loginInToday",
        "conditionValue": 1,
        "pikaReward": 40,
        "sort": 1,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "2": {
        "id": 2,
        "type": 1,
        "en": "Daily Farm 1 time",
        "cn": "挖1次矿",
        "avatar": "icon-FarmTime.svg",
        "link": "{\"page\":3,\"tab\":1,\"btn\":1,\"id\":102}",
        "noticeServer": 0,
        "conditionType": "ploughing",
        "conditionValue": 1,
        "pikaReward": 40,
        "sort": 2,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "3": {
        "id": 3,
        "type": 1,
        "en": "Daily Farm 3 time",
        "cn": "挖3次矿",
        "avatar": "icon-FarmTime.svg",
        "link": "{\"page\":3,\"tab\":1,\"btn\":1,\"id\":103}",
        "noticeServer": 0,
        "conditionType": "ploughing",
        "conditionValue": 3,
        "pikaReward": 120,
        "sort": 3,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "4": {
        "id": 4,
        "type": 1,
        "en": "Daily check 1 Box",
        "cn": "打开1个箱子",
        "avatar": "icon-BlueBox.svg",
        "link": 4,
        "noticeServer": 0,
        "conditionType": "checkBox",
        "conditionValue": 1,
        "pikaReward": 40,
        "sort": 4,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "5": {
        "id": 5,
        "type": 1,
        "en": "Daily check 3 Box",
        "cn": "打开3个箱子",
        "avatar": "icon-YellowBox.svg",
        "link": 5,
        "noticeServer": 0,
        "conditionType": "checkBox",
        "conditionValue": 3,
        "pikaReward": 120,
        "sort": 5,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "6": {
        "id": 6,
        "type": 1,
        "en": "Daily Invite 1 friend",
        "cn": "邀请好友1名",
        "avatar": "icon-Invite1.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":106}",
        "noticeServer": 0,
        "conditionType": "inviteInToday",
        "conditionValue": 1,
        "pikaReward": 200,
        "sort": 6,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "8": {
        "id": 8,
        "type": 2,
        "en": "Follow Pika X",
        "cn": "绑定Twitter",
        "avatar": "icon-twitter.svg",
        "link": "https://x.com/playCrystalFun",
        "noticeServer": 1,
        "conditionType": "bindingTwitter",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 7,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "9": {
        "id": 9,
        "type": 2,
        "en": "Join Pika Discord",
        "cn": "绑定Discord",
        "avatar": "icon-discord.svg",
        "link": "https://discord.gg/playcrystalfun",
        "noticeServer": 1,
        "conditionType": "bindingDiscord",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 8,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "10": {
        "id": 10,
        "type": 2,
        "en": "Join Pika TG channel",
        "cn": "加入TG频道",
        "avatar": "icon-Telegram.svg",
        "link": "https://t.me/Pika_offical/1399",
        "noticeServer": 1,
        "conditionType": "joinTGChannel_10",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 9,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "11": {
        "id": 11,
        "type": 2,
        "en": "Join Pika TG group",
        "cn": "加入TG群",
        "avatar": "icon-Telegram.svg",
        "link": "https://t.me/Pika_offical/1",
        "noticeServer": 1,
        "conditionType": "joinTGGroup",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 11,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 0
    },
    "12": {
        "id": 12,
        "type": 2,
        "en": "Invite 1 friend",
        "cn": "邀请好友1名",
        "avatar": "icon-Invite1.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":112}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 12,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "13": {
        "id": 13,
        "type": 2,
        "en": "Invite 3 friend",
        "cn": "邀请好友3名",
        "avatar": "icon-Invite3.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":113}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 3,
        "pikaReward": 1500,
        "sort": 13,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "14": {
        "id": 14,
        "type": 2,
        "en": "Invite 5 friend",
        "cn": "邀请好友5名",
        "avatar": "icon-Invite10.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":114}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 5,
        "pikaReward": 2500,
        "sort": 14,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "15": {
        "id": 15,
        "type": 2,
        "en": "Invite 10 friend",
        "cn": "邀请好友10名",
        "avatar": "icon-Invite10.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":115}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 10,
        "pikaReward": 5000,
        "sort": 15,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "16": {
        "id": 16,
        "type": 2,
        "en": "Invite 100 friend",
        "cn": "邀请好友100名",
        "avatar": "icon-Invite10.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":116}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 100,
        "pikaReward": 10000,
        "sort": 16,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "17": {
        "id": 17,
        "type": 2,
        "en": "Invite 500 friend",
        "cn": "邀请好友500名",
        "avatar": "icon-Invite10.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":117}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 500,
        "pikaReward": 20000,
        "sort": 17,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "18": {
        "id": 18,
        "type": 2,
        "en": "Bind TG wallet",
        "cn": "绑定TG钱包",
        "avatar": "icon-TGwallet.svg",
        "link": 15,
        "noticeServer": 0,
        "conditionType": "bindingTG",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 18,
        "checkType": 0,
        "checkData": "{}",
        "isShow": 1
    },
    "19": {
        "id": 19,
        "type": 2,
        "en": "Join News channel",
        "cn": "加入Pika新频道",
        "avatar": "icon-Telegram.svg",
        "link": "https://t.me/PikaAnn",
        "noticeServer": 0,
        "conditionType": "joinTGChannel_19",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 10,
        "checkType": 1,
        "checkData": "{\"bot_username\":\"pikagatebot\",\"chat_id\":\"@SurvivorAnn\"}",
        "isShow": 1
    },
    "20": {
        "id": 20,
        "type": 1,
        "en": "Survivor Daily Challenge(Endless Trials)",
        "cn": "进行1次survivor每日挑战（无尽试炼）",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_20",
        "conditionValue": 1,
        "pikaReward": 400,
        "sort": 20,
        "checkType": 2,
        "checkData": "{\"todayData\":\"trialNum\"}",
        "isShow": 1
    },
    "21": {
        "id": 21,
        "type": 1,
        "en": "Daily login to Survivor",
        "cn": "登录survivor游戏1次",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_21",
        "conditionValue": 1,
        "pikaReward": 200,
        "sort": 19,
        "checkType": 2,
        "checkData": "{\"resetTime\": true}",
        "isShow": 1
    },
    "22": {
        "id": 22,
        "type": 2,
        "en": "Play Main Chapter stages 1 time(s)",
        "cn": "参与survivor主线关卡1次",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_22",
        "conditionValue": 1,
        "pikaReward": 1000,
        "sort": 21,
        "checkType": 2,
        "checkData": "{\"historyData\": \"mainNum\"}",
        "isShow": 0
    },
    "23": {
        "id": 23,
        "type": 2,
        "en": "Survivor Accumulate recharge of 120 Gems",
        "cn": "survivor累积充值120钻石",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_23",
        "conditionValue": 120,
        "pikaReward": 5000,
        "sort": 22,
        "checkType": 2,
        "checkData": "{\"historyData\": \"moneyNum\"}",
        "isShow": 1
    },
    "24": {
        "id": 24,
        "type": 2,
        "en": "Survivor Accumulate recharge of 1400 Gems",
        "cn": "survivor累积充值1400钻石",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_24",
        "conditionValue": 1400,
        "pikaReward": 50000,
        "sort": 23,
        "checkType": 2,
        "checkData": "{\"historyData\": \"moneyNum\"}",
        "isShow": 1
    },
    "25": {
        "id": 25,
        "type": 1,
        "en": "Survivor Daily Activity Reaches 20",
        "cn": "survivor日活跃度达到20 +40Pika",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_25",
        "conditionValue": 20,
        "pikaReward": 40,
        "sort": 24,
        "checkType": 3,
        "checkData": "{}",
        "isShow": 1
    },
    "26": {
        "id": 26,
        "type": 1,
        "en": "Survivor Daily Activity Reaches 60",
        "cn": "survivor日活跃度达到60 +120Pika",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_25",
        "conditionValue": 60,
        "pikaReward": 120,
        "sort": 25,
        "checkType": 3,
        "checkData": "{}",
        "isShow": 1
    },
    "27": {
        "id": 27,
        "type": 1,
        "en": "Survivor Daily Activity Reaches 80",
        "cn": "survivor日活跃度达到80 +160Pika",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_25",
        "conditionValue": 80,
        "pikaReward": 160,
        "sort": 26,
        "checkType": 3,
        "checkData": "{}",
        "isShow": 1
    },
    "28": {
        "id": 28,
        "type": 2,
        "en": "Survivor Weekly Activity Reaches 20",
        "cn": "survivor周活跃度达到20",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_28",
        "conditionValue": 20,
        "pikaReward": 10,
        "sort": 27,
        "checkType": 4,
        "checkData": "{}",
        "isShow": 0
    },
    "29": {
        "id": 29,
        "type": 2,
        "en": "Survivor Weekly Activity Reaches 60",
        "cn": "survivor周活跃度达到60",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_28",
        "conditionValue": 60,
        "pikaReward": 10,
        "sort": 28,
        "checkType": 4,
        "checkData": "{}",
        "isShow": 0
    },
    "30": {
        "id": 30,
        "type": 2,
        "en": "Survivor weekly activity reaches 80 once",
        "cn": "survivor周活跃度达到80 +1500Pika",
        "avatar": "icon-Survivor.png",
        "link": "https://t.me/survivoriobot?start=d59ec9e537",
        "noticeServer": 0,
        "conditionType": "task_28",
        "conditionValue": 80,
        "pikaReward": 1500,
        "sort": 29,
        "checkType": 4,
        "checkData": "{}",
        "isShow": 1
    }
}