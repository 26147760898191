import { toast } from "react-toastify";
import ToastContent from "./Content";
import { COMMON_TOAST_TYPE_SUCCESS, toastCfg } from "./constance";

const cachContentIdList = [];

export function showToast(content, type = COMMON_TOAST_TYPE_SUCCESS, time = 5) {
  const contentId = `${content}_${type}`;
  const tempCfg = {
    ...toastCfg,
    autoClose: time * 1000,
    onClose: () => {
      const idx = cachContentIdList.indexOf(contentId);
      cachContentIdList.splice(idx, 1);
    },
  };
  if (cachContentIdList.indexOf(contentId) < 0) {
    // 防重
    toast(<ToastContent content={content} type={type} time={time} />, tempCfg);
    cachContentIdList.push(contentId);
  }
}
