export default function MeActiveSvg() {
  return (
    <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9863 16.78H0.369141V15.2028C0.369141 13.0253 2.13442 11.26 4.31204 11.26H9.04342C11.221 11.26 12.9863 13.0253 12.9863 15.2028V16.78ZM6.67773 9.68282C4.06462 9.68282 1.94628 7.56452 1.94628 4.9514C1.94628 2.3383 4.06462 0.219971 6.67773 0.219971C9.2908 0.219971 11.4091 2.3383 11.4091 4.9514C11.4091 7.56452 9.2908 9.68282 6.67773 9.68282Z" fill="url(#paint0_linear_1_573)"/>
      <defs>
      <linearGradient id="paint0_linear_1_573" x1="0.369141" y1="8.49997" x2="12.9863" y2="8.49997" gradientUnits="userSpaceOnUse">
      <stop stop-color="#16FFF7"/>
      <stop offset="1" stop-color="#FFFBE8"/>
      </linearGradient>
      </defs>
    </svg>
  )
}