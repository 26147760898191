import React from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import GameActiveSvg from '../components/svg/GameActiveSvg'
import GameNormalSvg from '../components/svg/GameNormalSvg'
import TaskActiveBarSvg from '../components/svg/TaskActiveBarSvg'
import TaskNormalBarSvg from '../components/svg/TaskNormalBarSvg'
import TapActiveSvg from '../components/svg/TapActiveSvg'
import TapNormalSvg from '../components/svg/TapNormalSvg'
import MeActiveSvg from '../components/svg/MeActiveSvg'
import MeNormalSvg from '../components/svg/MeNormalSvg'
import FriendsActiveSvg from '../components/svg/FriendsActiveSvg'
import FriendsNormalSvg from '../components/svg/FriendsNormalSvg'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TabBar from './components/TabBar'
import { useTranslation } from 'react-i18next'

function MainTabSelected({ selected }) {
  return (
    selected && (
      <span
        className='absolute z-0 h-[2px] w-[80%] bottom-0 shadow-[0_1px_0px_0_rgba(0,0,0,0.05)] rounded-none bg-[#766ffa]'
        data-slot='cursor'
        style={{ transform: 'none', transformOrigin: '50% 50% 0px' }}
      ></span>
    )
  )
}

function MainTab() {
  const navigate = useNavigate()
  const location = useLocation()
  const isActiveClassName = 'ml-2 text-base font-[600] text-[#766ffa]'
  const notActiveClassName = 'ml-2 text-base font-[600] text-[#626262]'
  return (
    <div className='sticky top-0 z-50 flex items-center justify-between bg-gray-100'>
      <div data-slot='base' className='inline-flex' aria-label='Tabs variants'>
        <div
          data-slot='tabList'
          className='flex p-1 h-fit items-center flex-nowrap overflow-x-scroll scrollbar-hide bg-transparent dark:bg-transparent rounded-none gap-0'
          id='react-aria6642947923-:r4l:'
          aria-label='Tabs variants'
          role='tablist'
          aria-orientation='horizontal'
        >
          <button
            data-slot='tab'
            tabIndex='-1'
            data-key='Deals'
            id='react-aria6642947923-:r4l:-tab-Deals'
            aria-selected='false'
            role='tab'
            className='z-0 w-full px-3 py-1 flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none'
            type='button'
            onClick={() => navigate('/')}
          >
            <MainTabSelected selected={location.pathname === '/'} />
            <div
              className='relative z-10 whitespace-nowrap transition-colors text-default-500 group-data-[selected=true]:text-[#766ffa] group-data-[selected=true]:font-bold'
              data-slot='tabContent'
            >
              <div className='flex items-center w-14'>
                <img
                  alt='Deals'
                  loading='lazy'
                  width='14'
                  height='14'
                  decoding='async'
                  data-nimg='1'
                  src={
                    location.pathname === '/'
                      ? '/fb-winner-blue.svg'
                      : '/fb-winner-grey.svg'
                  }
                  style={{ color: 'transparent' }}
                />
                <div
                  className={
                    location.pathname === '/'
                      ? isActiveClassName
                      : notActiveClassName
                  }
                >
                  Deals
                </div>
              </div>
            </div>
          </button>
          <button
            data-slot='tab'
            tabIndex='-1'
            data-key='Tap'
            id='react-aria6642947923-:r4l:-tab-Tap'
            aria-selected='false'
            role='tab'
            className='z-0 w-full px-3 py-1 flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none'
            type='button'
            onClick={() => navigate('/game')}
          >
            <MainTabSelected selected={location.pathname === '/game'} />
            <div
              className='relative z-10 whitespace-nowrap transition-colors text-default-500 group-data-[selected=true]:text-[#766ffa] group-data-[selected=true]:font-bold'
              data-slot='tabContent'
            >
              <div className='flex items-center w-14'>
                <img
                  alt='Tap'
                  loading='lazy'
                  width='14'
                  height='14'
                  decoding='async'
                  data-nimg='1'
                  src={
                    location.pathname === '/game' ? '/tap.svg' : '/tap-grey.svg'
                  }
                  style={{ color: 'transparent' }}
                />
                <div
                  className={
                    location.pathname === '/game'
                      ? isActiveClassName
                      : notActiveClassName
                  }
                >
                  Game
                </div>
              </div>
            </div>
          </button>
          <button
            data-slot='tab'
            tabIndex='0'
            data-key='Earn'
            id='react-aria6642947923-:r4l:-tab-Earn'
            aria-selected='true'
            role='tab'
            className='z-0 w-full px-3 py-1 flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none'
            type='button'
            data-selected='true'
            aria-controls='react-aria6642947923-:r4l:-tabpanel-Earn'
            onClick={() => navigate('/earn')}
          >
            <MainTabSelected selected={location.pathname === '/earn'} />
            <div
              className='relative z-10 whitespace-nowrap transition-colors text-default-500 group-data-[selected=true]:text-[#766ffa] group-data-[selected=true]:font-bold'
              data-slot='tabContent'
            >
              <div className='flex items-center w-14'>
                <img
                  alt='Earn'
                  loading='lazy'
                  width='14'
                  height='14'
                  decoding='async'
                  data-nimg='1'
                  src={
                    location.pathname === '/earn'
                      ? '/score.svg'
                      : '/score-grey.svg'
                  }
                  style={{ color: 'transparent' }}
                />
                <div
                  className={
                    location.pathname === '/earn'
                      ? isActiveClassName
                      : notActiveClassName
                  }
                >
                  Earn
                </div>
              </div>
            </div>
          </button>
          <button
            data-slot='tab'
            tabIndex='-1'
            data-key='Rank'
            id='react-aria6642947923-:r4l:-tab-Rank'
            aria-selected='false'
            role='tab'
            className='z-0 w-full px-3 py-1 flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none'
            type='button'
            onClick={() => navigate('/rank')}
          >
            <MainTabSelected selected={location.pathname === '/rank'} />
            <div
              className='relative z-10 whitespace-nowrap transition-colors text-default-500 group-data-[selected=true]:text-[#766ffa] group-data-[selected=true]:font-bold'
              data-slot='tabContent'
            >
              <div className='flex items-center w-14'>
                <img
                  alt='Rank'
                  loading='lazy'
                  width='14'
                  height='14'
                  decoding='async'
                  data-nimg='1'
                  src={
                    location.pathname === '/rank'
                      ? '/leadboard-blue.svg'
                      : '/leadboard.svg'
                  }
                  style={{ color: 'transparent' }}
                />
                <div
                  className={
                    location.pathname === '/rank'
                      ? isActiveClassName
                      : notActiveClassName
                  }
                >
                  Rank
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
      <img
        alt='history'
        loading='lazy'
        width='50'
        height='50'
        decoding='async'
        data-nimg='1'
        className='mr-2'
        src='/history.png'
        style={{ color: 'transparent' }}
      />
    </div>
  )
}

function NavTab() {
  const { t } = useTranslation();
  const tabs = [
    {
      path: '/game',
      name: t("pika.Play and earn"),
      svg: [<GameActiveSvg />, <GameNormalSvg />],
    },
    {
      path: '/task',
      name: t("pika.Task"),
      svg: [<TaskActiveBarSvg />, <TaskNormalBarSvg />],
    },
    { path: '/', name: t('pika.Tap-Tap'), svg: [<TapActiveSvg />, <TapNormalSvg />] },
    {
      path: '/friends',
      name: t("pika.Friends"),
      svg: [<FriendsActiveSvg />, <FriendsNormalSvg />],
    },
    { path: '/me', name: t("pika.Me"), svg: [<MeActiveSvg />, <MeNormalSvg />] },
  ]
  return <TabBar tabs={tabs} />
}

export default function Layout() {
  const location = useLocation()
  const pathname = location.pathname
  const isHome = pathname === '/'

  return (
    <div data-overlay-container='true'>
      <ToastContainer />
      <div
        role='region'
        aria-label='Notifications (F8)'
        tabIndex='-1'
        style={{ pointerEvents: 'none' }}
      >
        <ol
          tabIndex='-1'
          className='fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]'
        ></ol>
      </div>
      <div
        className='mx-auto flex min-h-screen max-w-3xl flex-col relative'
        style={{
          background: `linear-gradient(0deg, #000000, #000000), linear-gradient(360deg, #434343 0%, #000000 100%)`,
        }}
      >
        <main
          className='flex-1 h-screen'
          id='main_layout_container'
          style={{ zIndex: 999 }}
        >
          <Outlet />
          <NavTab />
        </main>

        {/* {pathname !== '/friends' ? ( */}
          <>
            <svg
              className={`absolute bottom-0 left-0 rotate-[43.82°]`}
              width='277'
              height='396'
              viewBox='0 0 277 396'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g filter='url(#filter0_f_1_197)'>
                <path
                  d='M109.301 172.748C83.0522 145.395 48.7711 127.448 12.5368 122.089C-23.6975 116.731 -59.5433 124.307 -88.6441 143.474C-117.745 162.642 -138.22 192.162 -146.439 226.799C-154.657 261.437 -150.088 298.954 -133.541 332.697C-116.995 366.44 -89.5401 394.229 -56.0459 411.136C-22.5517 428.043 14.8175 432.975 49.435 425.057C84.0524 417.14 113.681 396.885 133.066 367.884C152.452 338.883 160.342 303.011 155.337 266.628L3.18832 274.577L109.301 172.748Z'
                  fill='url(#paint0_linear_1_197)'
                  fillOpacity='0.5'
                />
              </g>
              <defs>
                <filter
                  id='filter0_f_1_197'
                  x='-270.428'
                  y='0.412109'
                  width='547.231'
                  height='548.33'
                  filterUnits='userSpaceOnUse'
                  colorInterpolationFilters='sRGB'
                >
                  <feFlood floodOpacity='0' result='BackgroundImageFix' />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='BackgroundImageFix'
                    result='shape'
                  />
                  <feGaussianBlur
                    stdDeviation='60'
                    result='effect1_foregroundBlur_1_197'
                  />
                </filter>
                <linearGradient
                  id='paint0_linear_1_197'
                  x1='8.15134'
                  y1='492.154'
                  x2='35.8822'
                  y2='59.4131'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#00C9FF' />
                  <stop offset='1' stopColor='#92FE9D' />
                </linearGradient>
              </defs>
            </svg>
            <svg
              className='absolute top-0 right-0 rotate-[43.82°]'
              width='212'
              height='431'
              viewBox='0 0 212 431'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g filter='url(#filter0_f_1_196)'>
                <ellipse
                  cx='274.188'
                  cy='156.578'
                  rx='147.069'
                  ry='160.422'
                  transform='rotate(-43.8201 274.188 156.578)'
                  fill='url(#paint0_linear_1_196)'
                  fillOpacity='0.5'
                />
              </g>
              <defs>
                <filter
                  id='filter0_f_1_196'
                  x='0.572266'
                  y='-117.589'
                  width='547.231'
                  height='548.333'
                  filterUnits='userSpaceOnUse'
                  colorInterpolationFilters='sRGB'
                >
                  <feFlood floodOpacity='0' result='BackgroundImageFix' />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='BackgroundImageFix'
                    result='shape'
                  />
                  <feGaussianBlur
                    stdDeviation='60'
                    result='effect1_foregroundBlur_1_196'
                  />
                </filter>
                <linearGradient
                  id='paint0_linear_1_196'
                  x1='127.12'
                  y1='317'
                  x2='446.756'
                  y2='23.9703'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#00C9FF' />
                  <stop offset='1' stopColor='#92FE9D' />
                </linearGradient>
              </defs>
            </svg>
          </>
        {/* // ) : (
        //   <img
        //     className='absolute top-0 left-0 rotate-[135]'
        //     src='/friends-bg.png'
        //     alt='bg'
        //   />
        // )} */}
      </div>
    </div>
  )
}
