import axios from "axios";
import { getHttpToken, setHttpToken } from "./httpToken";
import { HOST_API } from "../config";

export const SUCCESS_CODE = 0;

const service = axios.create({
  baseURL: HOST_API,
  timeout: 15000,
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const { code, result, message } = response?.data;
    const { showError = true, cacheToken } = response?.config;
    if (result?.code === SUCCESS_CODE) {
      if (cacheToken) {
        const { token, accessToken } = result;
        if (token || accessToken) {
          setHttpToken(token || accessToken);
        }
      }
      return result;
    } else {
      if (!result?.hasOwnProperty("code")) {
        if (code === SUCCESS_CODE) {
          return result;
        }
      }
      const msg = message || result?.message || "Somthing run wrong";
      return Promise.reject(result);
    }
  },
  (error) => {
    const msg = "Somthing run wrong";
    if (error.config?.showError) {
    }
    return Promise.reject(error);
  }
);

export const httpGet = (url, param = null) => {
  let paramStr = "";
  if (param) {
    for (let k in param) {
      const val = param[k];
      paramStr += `${k}=${JSON.stringify(val)}&`;
    }
    paramStr = paramStr.slice(0, paramStr.length - 1);
  }
  const requestUrl = param ? `${url}?${paramStr}` : url;
  return service.get(requestUrl);
};

export const httpPost = (url, pParam = {}, options = null) => {
  const param = {
    // options: JSON.stringify(pParam),
    ...pParam,
  };
  let hasAuthorization = options?.cacheToken ? false : true;
  if (options?.hasOwnProperty("hasAuthorization")) {
    hasAuthorization = options?.hasAuthorization;
  }
  const postOption = { ...options };
  postOption.headers = postOption.headers || {};
  if (hasAuthorization) {
    const token = getHttpToken();
    postOption.headers["Authorization"] = `Bearer ${token}`;
  }

  return service.post(url, param, { ...postOption });
};
