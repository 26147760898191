import NiceModal, { useModal } from "@ebay/nice-modal-react";
import CloseBtn from "../../../../components/CloseBtn";
import { getShowAddress } from "../../../../utils";
import RankItem from "../../components/RankList/RankItem";
import { ConfigProvider, Pagination, theme } from "antd";
import { useEffect, useState } from "react";
import { getFarmerInvitesCeche } from "../../../../api/friends";
import EmptySvg from "../../../../components/svg/EmptySvg";
const { darkAlgorithm, compactAlgorithm } = theme;

const PAGE_SIZE = 4;

function InnerModalContent(props) {
  const { tgName, uid } = props;
  const modal = useModal();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: PAGE_SIZE,
    total: 0,
  });

  const [inviteRanks, setInviteRanks] = useState([
    // {
    //   rankNumber: 1,
    //   tgName: "123",
    //   headId: "123",
    //   totalPoint: 100,
    // },
    // {
    //   rankNumber: 2,
    //   tgName: "12345",
    //   headId: "123",
    //   totalPoint: 10000,
    // },
    // {
    //   rankNumber: 2,
    //   tgName: "12345",
    //   headId: "123",
    //   totalPoint: 10000,
    // },
    // {
    //   rankNumber: 2,
    //   tgName: "12345",
    //   headId: "123",
    //   totalPoint: 10000,
    // },
  ]);

  function onClose() {
    modal.remove();
  }

  useEffect(() => {
    // TODO:请求列表
  }, [pagination]);

  function onPageChange(current = 1, pageSize = PAGE_SIZE) {
    setPagination({
      ...pagination,
      current: current,
      pageSize: pageSize,
    });
  }

  async function getListInfo() {
    const { datas } = await getFarmerInvitesCeche({ target_uid: uid });
    setInviteRanks(datas);
  }

  useEffect(() => {
    if (modal.visible && uid) {
      getListInfo();
      onPageChange();
    }
  }, [uid, modal.visible]);

  return (
    <div className="fixed w-full h-full z-[9999] top-0 left-0 bg-[#000000BF]">
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-[345px] flex flex-col items-center h-[490px] bg-[#1D2122] rounded-[24px] px-[20px] py-[18px]">
          <div className="flex items-center justify-between w-[100%]">
            <div className="text-[#FFFFFF] text-[17px] font-bold flex items-center">
              <img
                className="w-[8px] h-[11px] mr-[3px]"
                src="/user_point_icon.svg"
              />
              <span className="text-[#17FFF7]">
                {getShowAddress(tgName, 2, 2)}
              </span>
              's invitation
            </div>
            <CloseBtn onClose={onClose} />
          </div>
          <div className="flex flex-col mt-[15px] w-[320px]">
            {inviteRanks.length > 0 ? (
              inviteRanks?.map((item) => (
                <RankItem key={item.rankNumber} data={item} />
              ))
            ) : (
              <div className="flex flex-col items-center text-[#FFFFFF] h-[370px] justify-center">
                <EmptySvg />
                No Datas
              </div>
            )}
          </div>
          <div className="mt-[15px]">
            <ConfigProvider
              theme={{
                algorithm: [darkAlgorithm, compactAlgorithm],
                token: {
                  colorPrimary: "#17fff7",
                  colorInfo: "#17fff7",
                },
              }}
            >
              <Pagination
                size="small"
                {...pagination}
                onChange={onPageChange}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NiceModal.create(InnerModalContent);
