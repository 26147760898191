import { useTonAddress } from "@tonconnect/ui-react";
import { getShowAddress } from "../../../../utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUsdtInfo } from "../../../../store/features/assetsSlice";

export default function WithdrawModal(props) {

  const dispatch = useDispatch();
  const { onClick, onClose, title, list } = props;
  const usdtInfo = useSelector((state) => state.assets.usdtInfo);
  const walletAddress = useTonAddress();

  const onConfirm = () => {
    onClick?.()
  }

  const chooseUSDT = (idx, val) => {
    dispatch(setUsdtInfo({
      index: idx,
      usdt: val,
      text: ""
    }))
  }

  return (
    <div className="fixed z-[1000] top-[150px] left-0 w-full h-full">
      <div className="px-4 flex items-center justify-center bg-[#00000080]">
        <div
          className="max-w-3xl flex-1 min-h-[385px] relative"
          style={{
            background: "linear-gradient(0deg, #232D31, #232D31), linear-gradient(320.48deg, #17242C -37.21%, #173031 2.88%, #0B171A 80.22%, #2A7E7E 98.48%)",
            borderRadius: "10px",
            boxSizing: "border-box"
          }}
        >
          <img
            className="w-[16px] h-[16px] absolute z-40 right-3 top-3 cursor-pointer"
            onClick={onClose}
            src="/comom-close.png"
          />
          <p className="text-white font-bold text-xl top-2 absolute w-full text-center">
            {title}
          </p>
          <div className="absolute top-14 px-4 w-full">
            <div className="relative border-[2px] rounded-[5px] border-[#2A4443] px-[15px] py-2.5 whitespace-nowrap flex items-center">
              <span className="text-[#FFFFFFB2] pr-2">Wallet address:</span>{getShowAddress(walletAddress)}
              <img
                alt="checked"
                loading="lazy"
                width="18"
                height="18"
                decoding="async"
                data-nimg="1"
                srcSet="/address-checked.png 1x, /address-checked.png 2x"
                src="/address-checked.png"
                style={{ color: "transparent" }}
                className="absolute -top-[1px] -left-[1px]"
              />
            </div>
          </div>
          <div className="absolute top-32 px-4 w-full">
            <p className="mb-3 font-bold text-sm text-[#FFFFFFB2]">Select amount</p>
            <ul className="grid grid-cols-3 gap-3">
              {list.map((item, index) => (
                <li
                  key={item} 
                  className="relative text-center font-[800] text-lg text-[#38EBDA] border-1 rounded-[5px] py-2.5 px-[13px] bg-[#475C5A] border-[2px] border-[#FFFFFF33] cursor-pointer"
                  style={
                    index === usdtInfo?.index ? { background: "#26A69A", border: "2px solid #38EBDA", color: "#ffffff", boxShadow: "0px 0px 10px rgba(56, 235, 218, 0.3)" } : { border: "2px solid rgba(255, 255, 255, 0.2)" }
                  }
                  onClick={() => chooseUSDT(index, item)}
                >
                  <p>
                    {item}
                    <span className="text-[12px] pl-1 text-white font-semibold">USDT</span>
                  </p>
                  {
                    index === usdtInfo?.index && (
                      <img
                        alt="checked"
                        loading="lazy"
                        width="18"
                        height="18"
                        decoding="async"
                        data-nimg="1"
                        srcSet="/usdt-checked.png 1x, /usdt-checked.png 2x"
                        src="/usdt-checked.png"
                        style={{ color: "transparent" }}
                        className="absolute -top-[1px] -left-[1px]"
                      />
                    )
                  }
                </li>
              ))}
            </ul>
          </div>
        <p
          className="absolute bottom-[70px] font-semibold text-sm w-full px-4 text-center text-[#C7FEDD]">
          {usdtInfo?.text}
        </p>
        <div className="px-4 absolute bottom-[20px] w-full">
          <button
            className="w-full h-[46px] rounded-[10px] text-[19px] font-extrabold text-black hover: animate-pulse"
            style={{
              background: "linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)",
            }}
            onClick={onConfirm}
          >
            Confirm submission
          </button>
        </div>
        </div>
      </div>
    </div>
  )
}