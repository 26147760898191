import { httpPost } from "../../utils/httpRequest";

/**
 * 获取用户信息
 * @returns
 * @see https://candy-porpoise-601.notion.site/2b0d1016b45641ec8eef32b2a1b19509
 */
export async function queryUserInfo() {
  const reqData = await httpPost("/v1/farming/getPikaInfos");
  return reqData;
}

/**
 * 获取资产信息
 * @returns
 * @see https://candy-porpoise-601.notion.site/2b0d1016b45641ec8eef32b2a1b19509
 */
export async function queryUserAssetsInfo() {
  const reqData = await httpPost("/v1/farming/getAssetsInfos");
  return reqData;
}

/**
 * 获取历史记录
 * @returns
 * @see https://candy-porpoise-601.notion.site/2b0d1016b45641ec8eef32b2a1b19509
 */
export async function queryHistory(symbol) {
  const reqData = await httpPost("/v1/farming/getFarmerHistorys", { symbol });
  return reqData;
}