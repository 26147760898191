import React, { useState } from 'react'
import Header from '../../components/Header'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CheckedSvg } from '../me/svg/CheckedSvg'
import { useSelector } from 'react-redux'

// const lngLabels = [
//   { label: 'English', value: 'en' },
//   // { label: "Français", value: "fr" },
//   // { label: "Deutsch", value: "de" },
//   { label: 'Chinese,Simplified (简体中文)', value: 'zh' },
//   { label: 'Chinese,Traditional (繁體中文)', value: 'zh-TW' },
// ]

export default function LanguageSelector() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem('i18nextLng')
  )
  const lngOptions = useSelector((state) => state.assets.lngOptions)

  const toggleLanguage = (e, lng) => {
    setCurrentLang(lng)
    i18n.changeLanguage(lng)
  }
  return (
    <div
      className='min-h-screen select-none text-white p-4'
      style={{
        background: `linear-gradient(0deg, #000000, #000000), linear-gradient(360deg, #434343 0%, #000000 100%)`,
      }}
    >
      <Header onClick={() => navigate('/settings')} title='Select_Language' />
      <div className='rounded-[7px] bg-[#141414] overflow-hidden'>
        {lngOptions.map((item, index) => (
          <>
            <div
              key={item.value}
              className='flex items-center justify-between cursor-pointer py-2.5 px-[15px] text-[#FFFFFFB2]'
              onClick={(e) => toggleLanguage(e, item.value)}
            >
              <span className={item.value === currentLang && 'text-white'}>
                {item.label}
              </span>
              {item.value === currentLang && <CheckedSvg />}
            </div>
            <div className='w-full border border-[#FFFFFF1A] last:border-none ml-[15px]'></div>
          </>
        ))}
      </div>
    </div>
  )
}
