import classNames from "classnames";
import { dicCfg } from "./constance";
import styles from "./index.module.css";

function ToastContent(props) {
  const { type, content, time } = props;
  const { title, icon, style } = dicCfg[type];

  return (
    <div className={classNames(styles[style], styles["toast-div"])}>
      <img src={icon} className={styles["alert-icon"]} />
      <div className={styles["alert-bd"]}>
        <div className={styles["alert-tit"]}>{title}</div>
        <div className={styles["alert-des"]}>{content}</div>
      </div>
      <div className={styles["countdown-bar"]}>
        <div
          className={styles["countdown-cur"]}
          style={{ animation: `${styles["proccessBar"]} ${time}s` }}
        />
      </div>
    </div>
  );
}

export default ToastContent;
