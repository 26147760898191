import { useEffect, useState } from "react";
import TabBarItem from "./TabBarItem";

function TabBar(props) {
  const { onClick, tabs, value } = props;
  const [curTab, setCurTab] = useState("");

  useEffect(() => {
    setCurTab(value);
  }, [value]);

  function onTabChange(val) {
    setCurTab(val);
    onClick?.(val);
  }

  return (
    <div className="flex items-center">
      <div data-slot="base" className="inline-flex" aria-label="Tabs variants">
        <div
          data-slot="tabList"
          className="flex h-fit items-center space-x-5 flex-nowrap scrollbar-hide bg-transparent dark:bg-transparent rounded-none gap-0"
          id="react-aria7709663581-:r5:"
          aria-label="Tabs variants"
          role="tablist"
          aria-orientation="horizontal"
        >
          {tabs?.map((item, idx) => {
            return (
              <TabBarItem
                key={idx}
                isSelect={curTab === item.value}
                {...item}
                onClick={onTabChange}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TabBar;
