import { useSelector } from 'react-redux'
import UserSvg from '../svg/UserSvg'
import VerifySvg from '../svg/VerifySvg'

export default function VerifyCard({ onClick }) {
  const checkState = useSelector((state) => state.user.checkState)

  return (
    <div
      className='w-full h-[62px] p-2.5 text-white flex items-center justify-between mt-3'
      style={{
        background: `linear-gradient(90deg, rgba(0, 139, 199, 0.3) 0%, rgba(0, 199, 187, 0.3) 100%)`,
        boxShadow: `0px 0px 10px rgba(0, 255, 240, 0.2)`,
        borderRadius: '10px',
      }}
    >
      <div className='flex items-center mr-3 font-medium text-[15px] '>
        <VerifySvg />
        {!checkState ? (
          <p className='ml-4'>Verify community to get level up</p>
        ) : (
          <div className='ml-4'>
            <p>t.me/PIKA_BiuBiu</p>
            <div className='flex items-center'>
              <UserSvg />
              <span className='pl-1 text-[#FFFFFFB2] text-sm font-medium'>
                0
              </span>
            </div>
          </div>
        )}
      </div>
      {!checkState ? (
        <button
          className='w-[80px] h-[34px] flex items-center justify-center p-3 rounded-[34px] text-black font-bold text-[17px]'
          style={{
            background: 'linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)',
          }}
          onClick={onClick}
        >
          Verify
        </button>
      ) : (
        <button
          className='w-[80px] h-[34px] flex items-center justify-center p-3 rounded-[34px] text-black font-bold text-[17px]'
          style={{
            border: '2px solid rgba(23, 255, 247, 0.5)',
            background:
              'linear-gradient(0deg, #17FFF7, #17FFF7), linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            textFillColor: 'transparent',
            WebkitTextFillColor: 'transparent',
          }}
          onClick={onClick}
        >
          Change
        </button>
      )}
    </div>
  )
}
