import { toast } from "react-toastify";
import CopySvg from "../../../components/svg/CopySvg";
import copy from "copy-to-clipboard";
import { COMMON_TOAST_TYPE_SUCCESS } from "../../../components/showToast/constance";
import { showToast } from "../../../components/showToast";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { createShakeAni } from "../../../utils/cssAni";
import "../../../styles/shakeAni.css";
import { useTranslation } from "react-i18next";

export default function InviteBtn() {
  const { t } = useTranslation();
  const tgInvateLink = useSelector((state) => state.common.tgInvateLink);
  const shareText =
    "Decentralized Gaming Mega-ecosystem and Studio. Let's play-and-earn airdrop right now!";

  function telegramForwardButton(url, txt = "", justCopy = false) {
    const content = `url=${url}&text=${txt}`;
    // const encodedContent = encodeURIComponent(content);
    const shareUrl = `https://t.me/share/url?${content}`;

    console.log("share url:::", shareUrl);

    if (justCopy && url) {
      copy(url);
      showToast("Copy success", COMMON_TOAST_TYPE_SUCCESS, 1);
    } else {
      window.Telegram.WebApp.openTelegramLink(shareUrl);
    }
  }

  return (
    <div className="flex w-full items-center justify-between py-4">
      <div className="w-[80%] flex-row items-center">
        <div className="relative z-10 px-3 w-full">
          <button
            className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover rounded-full py-3 text-sm font-bold text-black w-full h-[50px] -mt-1"
            type="button"
            style={{
              background: "linear-gradient(90deg, #8BFFF8 0%, #EFFCCA 100%)",
              fontSize: "17px",
            }}
            id="inviteBtn"
            onClick={() => {
              console.log(tgInvateLink);
              telegramForwardButton(tgInvateLink, shareText);
            }}
          >
            {t("pika.Invite Friends")}
          </button>
        </div>
      </div>
      <div
        className="flex justify-end cursor-pointer"
        onClick={() => {
          telegramForwardButton(tgInvateLink, shareText, true);
        }}
      >
        <CopySvg />
      </div>
    </div>
  );
}
