import { useTranslation } from "react-i18next"

export default function Header({ onClick, title }) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center mb-5">
      <img
        onClick={onClick}
        alt="LIKE_RT_BBY_SUMMIT_1"
        loading="lazy"
        width="23"
        height="23"
        decoding="async"
        data-nimg="1"
        srcSet="/back-arrow.svg 1x, /back-arrow.svg 2x"
        src="/back-arrow.svg"
        style={{ color: "transparent" }}
        className="cursor-pointer"
      />
      <span className="flex-1 block text-center text-[27px] font-[700]">{t(`pika.${title}`)}</span>
    </div>
  )
}