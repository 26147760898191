import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { queryUserInfo } from "../../api/assets";

const initialState = {
  userInfo: {},
  isVerifyPage: false,
  checkState: false
};

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async () => {
    const res = await queryUserInfo();
    return res;
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setVerifyPage: (state, action) => {
      state.isVerifyPage = action.payload;
    },
    setCheckState: (state, action) => {
      state.checkState = action.payload;
      console.log(state.checkState, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.userInfo = action.payload
      })
      .addCase(fetchUser.rejected, (state, err) => {
        console.log('userInfoError', err)
      })
  }
});

export const { setUserInfo, setVerifyPage, setCheckState } = userSlice.actions;

// 默认导出
export default userSlice.reducer;