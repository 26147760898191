import { useNavigate } from "react-router-dom";
import AssetsCom from "../../components/assets/AssetsCom";
import ConnectBtn from "./components/ConnectBtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { fetchAssets, setUsdtInfo } from "../../store/features/assetsSlice";
import WithdrawBtn from "./components/WithdrawBtn";
import { useTonAddress, useTonWallet } from "@tonconnect/ui-react";
import Header from "../../components/Header";
import { showToast } from "../../components/showToast"
import { COMMON_TOAST_TYPE_WARNNING } from "../../components/showToast/constance"
import WithdrawModal from "./components/WithdrawModal";
import { useDebounceFn } from "ahooks";
import { useTranslation } from "react-i18next";

function AssetsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const assetsInfo = useSelector((state) => state.assets.assetsInfo);
  const usdtInfo = useSelector((state) => state.assets.usdtInfo);
  const walletAddress = useTonAddress();
  const [dateTime, setDateTime] = useState();
  const [ isDisabled, setIsDisabled ] = useState(false)
  const [ showWithdrawModal, setShowWithdrawModal ] = useState(false);

  const usdtList = [10, 50, 100, 200, 300, 500]

  useEffect(() => {
    console.log(walletAddress, "<====wallet");
    dispatch(fetchAssets());
  }, []);

  const refresh = () => {
    const dateTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
    setDateTime(dateTime);
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleWithdraw = () => {
    const { totalBalance } = assetsInfo
    if (totalBalance * 1 >= 0) {
      setShowWithdrawModal(true);
    }
  }

  useEffect(() => {
    if (!assetsInfo?.totalBalance || assetsInfo?.totalBalance*1 === 0) {
      setIsDisabled(true)
    }
  }, [assetsInfo?.totalBalance])

  const {run} = useDebounceFn(() => {
    if (usdtList[usdtInfo?.index] > assetsInfo?.totalBalance * 1) {
      dispatch(setUsdtInfo({
        ...usdtInfo,
        text: "Insufficient USDT balance"
      }))
      return;
    } else {
      // TODO 调接口
      dispatch(setUsdtInfo({
        ...usdtInfo,
        text: "Withdrawals are expected to arrive in 1-3 days"
      }))
    }
    setShowWithdrawModal(false);
  }, { wait: 500 })

  return (
    <div
      className="min-h-screen select-none text-white p-4"
      style={{
        background: `linear-gradient(0deg, #000000, #000000), linear-gradient(360deg, #434343 0%, #000000 100%)`,
      }}
    >
      <Header onClick={() => navigate("/me")} title="Assets" />
      <AssetsCom />
      <div className="flex items-center space-x-2 my-4">
        <span className="text-[#FFFFFFB2]">{dateTime}</span>
        <img
          className="cursor-pointer"
          alt="photo"
          loading="lazy"
          width="16"
          height="16"
          decoding="async"
          data-nimg="1"
          srcSet="/refresh.png 1x, /refresh.png 2x"
          src="/refresh.png"
          onClick={refresh}
        />
      </div>
      {walletAddress ? <WithdrawBtn onClick={handleWithdraw} disabled={isDisabled} /> : <ConnectBtn />}
      { walletAddress && <p className="text-sm text-slate-300 mt-1">{t("pika.Withdrawals will be available after the end of the Invite Friends Season (after September 30th)")}.</p> }
      {/* <div className="mt-4">
        <div className="text-[17px] font-semibold">History</div>
        <ul className="list-none list-outside">
          <li className="flex items-start py-[15px] border-b border-[#FFFFFF1A]">
            <div className="pl-2 pr-3 pt-2.5">
              <span
                className="block w-[3px] h-[3px] bg-white -rotate-45 "
                style={{
                  boxShadow: "0px 0px 0px 3px rgba(255, 255, 255, 0.2)"
                }}
              ></span>
            </div>
            <div className="text-[#FFFFFFCC] text-sm font-medium">
              <div>{dayjs("2024-8-13 20:30:20").format("YYYY-MM-DD HH:mm:ss")}</div>
              <div className="flex items-center">
                <span className="text-[#17FFF7] font-bold text-lg mr-2">+20</span>
                <span className="text-white mr-1">Withdrawal successful <span className="text-[#17FFF7] font-bold text-lg">10</span> USDT</span>
              </div>
            </div>
          </li>
        </ul>
      </div> */}

      {showWithdrawModal && (
        <WithdrawModal
          title={"Withdrawal Settings"}
          list={usdtList}
          onClick={run}
          onClose={() => {
            dispatch(setUsdtInfo({
              index: 0,
              usdt: 10,
              text: ""
            }))
            setShowWithdrawModal(false);
          }}
        />
      )}
    </div>
  );
}
export default AssetsPage;
