export default function RightArrowSvg() {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.76368 1L9 1M9 1L9 7.01948M9 1L1 9'
        stroke='white'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
