import NiceModal, { useModal } from "@ebay/nice-modal-react";
import CloseBtn from "../../../../components/CloseBtn";
import IconItem from "./IconItem";
import { useTranslation } from "react-i18next";

function InnerModalContent(props) {
  const { t } = useTranslation();
  const modal = useModal();

  function onClose() {
    modal.remove();
  }

  function onClick() {
    onClose();
  }

  return (
    <div className="fixed w-full h-full z-[9999] top-0 left-0 bg-[#000000BF]">
      <div
        className="w-full h-full flex items-center justify-center"
        onClick={onClick}
      >
        <div className="w-[320px] min-h-[380px] bg-[#1D2122] rounded-[24px] px-[20px] py-[18px]">
          <div className="flex items-center justify-between">
            <div className="text-[#FFFFFF] text-[17px] font-bold">{t("pika.Value")}</div>
            <CloseBtn onClose={onClose} />
          </div>
          <p className="text-white text-[12px] py-2 pb-3 text-nowrap">{t("pika.date_update_tip")}</p>
          <div className="flex flex-col">
            <IconItem
              icon="/user_point_icon.svg"
              label={t("pika.Invited headcount display")}
            />
            <IconItem
              icon="/ton_icon.png"
              label={t("pika.Ton recharge amount display")}
            />
            <IconItem
              icon="/ustd_icon.png"
              label={t("pika.USDT recharge amount display")}
            />
            <IconItem
              icon="/usd_icon.png"
              label={t("pika.USD recharge amount display")}
            />
            <IconItem
              icon="/star_icon.png"
              label={t("pika.Star recharge amount display")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NiceModal.create(InnerModalContent);
