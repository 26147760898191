import { useState } from "react"
import TabItem from "./TabItem"

export default function TabBar({ fn }) {
  const tabs = ["$Pika", "$CFUN", "$USDT"]
  const [idx, setIdx] = useState(0);

  function onClick(idx) {
    setIdx(idx);
    fn(idx === 0 ? "Pika" : idx === 1 ? "CFUN" : "USDT")
  }

  return (
    <ul
      className="w-full flex items-center justify-between bg-[#152529] p-2 rounded-3xl"
    >
      {
        tabs.map((tab, index) => (
          <TabItem key={index} title={tab} isActive={index === idx} onClick={() => onClick(index)} />
        ))
      }
    </ul>
  )
}