import { useNavigate } from "react-router-dom";
import AssetsCom from "../../../components/assets/AssetsCom";

function UserAssets() {
  const navigate = useNavigate()

  return (
    <div
      className="p-5 text-white rounded-xl w-full"
      style={{
        background: `linear-gradient(0deg, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)), linear-gradient(244.58deg, rgba(0, 42, 51, 0.8) 17.78%, rgba(4, 37, 44, 0.8) 82.22%)`
      }}
    >
      <AssetsCom onClick={() => navigate("/me/assets")} />
    </div>
  )
}

export default UserAssets;
