import { useEffect, useRef, useState } from "react";
import TapOreBoxTemplates from "../../../../constants/TapOreBoxTemplates";
import { BOX_STATUS_CHECK, BOX_STATUS_UNLOCK } from "../../constance";
import InfoContent from "./InfoContent";
import MainContent from "./MainContent";
import PointInfo from "./PointInfo";
import { useInterval } from "ahooks";
import { getBoxTapInfoById, setBoxTapInfoById } from "../../biz";
import { tapBox } from "../../../../api/box";
import Loading from "../../../../components/Loading/Loading";
import { useDispatch } from "react-redux";
import { setJumpChain } from "../../../../store/features/commonSlice";

const STATUS_UNLOCK = "unlock";

function CardBox(props) {
  const { data, onClick, no, onOpen, oreRemainAmount, isLoading } = props;
  const { boxId, progress, status } = data;
  const [selfH, setSelfH] = useState(0);
  const cfg = TapOreBoxTemplates[no];
  const dispatch = useDispatch();

  async function intervalFn() {
    const nowTime = new Date().getTime();
    const curTapBoxData = getBoxTapInfoById(boxId);
    const lastTapTime = curTapBoxData.lastTapTime;
    if (
      lastTapTime > 0 &&
      nowTime - lastTapTime >= 950 &&
      curTapBoxData.tapCount > 0 &&
      progress < cfg.progressMax
    ) {
      // 同步到后端;
      tapBox(boxId, curTapBoxData.tapCount);
      // 刷新本地数据
      curTapBoxData.tapCount = 0;
      curTapBoxData.lastTapTime = 0;
      setBoxTapInfoById(boxId, curTapBoxData);
    }
  }

  useInterval(() => {
    intervalFn();
  }, 1000);

  function onBoxClick() {
    if (progress < cfg.progressMax && oreRemainAmount > 1) {
      const nowTime = new Date().getTime();

      const curTapBoxData = getBoxTapInfoById(boxId);

      curTapBoxData.tapCount += 1;
      curTapBoxData.lastTapTime = nowTime;
      setBoxTapInfoById(boxId, curTapBoxData);
      onClick(boxId);
    }
  }

  const pregressVal =
    status === BOX_STATUS_UNLOCK
      ? cfg.progressMax > 0
        ? progress / cfg.progressMax
        : 0
      : 1;
  console.log(progress, cfg.progressMax, pregressVal);

  function onLockClick() {
    if (status === BOX_STATUS_CHECK) {
      onOpen?.(no);
    } else if (status === BOX_STATUS_UNLOCK) {
      if (cfg?.link) {
        const linkData = JSON.parse(cfg?.link);
        dispatch(setJumpChain(linkData));
      }
    }
  }

  useEffect(() => {
    const dom = document?.getElementById("game_swiper_container");
    const dh = dom?.clientHeight;

    setSelfH(dh);
  }, []);

  return (
    <div
      className="w-full flex justify-center h-full"
      style={{ height: `${selfH}px` }}
    >
      <div
        className="w-[88%] min-h-[240px] flex flex-col items-center h-full bg-[#212121] rounded-[12px] pt-[5px]"
        id="game_box_div"
      >
        <PointInfo value={cfg?.turnonReward} />
        <MainContent
          progress={pregressVal}
          isLoading={isLoading}
          onClick={onBoxClick}
          type={boxId}
          status={status}
        />
        <InfoContent
          isLoading={isLoading}
          onOpen={onLockClick}
          name={cfg?.en}
          type={boxId}
          status={status}
        />
        {isLoading && (
          <div className="fixed z-[1000] top-24 left-1/2 -translate-x-1/2 pointer-events-none">
            <Loading type="bubbles" color="rgba(255,255,255,0.5)" />
          </div>
        )}
      </div>
    </div>
  );
}

export default CardBox;
