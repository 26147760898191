import LazyLoad from 'react-lazyload';
import RightArrowSvg from '../../../components/svg/RightArrowSvg';
import { useTranslation } from 'react-i18next';

export default function Title(props) {
  const { t } = useTranslation();
  const { Title, Describe } = props
  return (
    <div>
      <div className='w-full h-fit min-h-[177px]'>
        {/* 图片填充 */}
        {/* <LazyLoad> */}
          <img className='w-full rounded-xl' src={props["Pic"]} alt='' />
        {/* </LazyLoad> */}
      </div>
      <div className='flex flex-col text-lg text-white font-bold leading-5 my-4 space-y-2.5'>
        <span>{t(`pika.${Title}`)}</span>
        <span className='text-base font-normal text-[#FFFFFFB2]'>
          {t(`pika.${Describe}`)}
        </span>
        <a
          href={props["More details URL"]}
          target='_blank'
          rel='noreferrer'
          className="flex items-center space-x-1 text-sm font-semibold bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-transparent bg-clip-text"
        >
          <span className="font-bold mr-1">{t("pika.MORE DETAILS")}</span>
          <RightArrowSvg />
        </a>
      </div>
    </div>
  )
}
