import { hasExpired } from "../../../utils";
import EarnBtn from "./EarnBtn";
import EndsTime from "./EndsTime";
import Title from "./Title";
import Winners from "./Winners";

function EarnBlock(props) {
  const { data } = props
  return (
    <div
      className="rounded-lg p-[15px] shadow-lg"
      style={{
        background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #182729 100%), #212121"
      }}
    >
      <div className="flex-col">
        <Winners winners={data.Winners} total={data["Reward total"]} />
        <Title {...data} />
        <EndsTime {...data} />
      </div>
      <EarnBtn disabled={hasExpired(data?.Endin)} {...data} />
    </div>
  );
}

export default EarnBlock;
