import InfoBlock from "./InfoBlock";

function InfluenceInfo(props) {
  const { data } = props;
  const { directInvited, direct_value, indirectInvited, indirect_value } = data;
  return (
    <div
      className="flex flex-col justify-start mt-[15px] rounded-[15px] py-[12px] px-3"
      style={{
        background:
          "linear-gradient(180deg, rgba(0, 138, 146, 0.7) 0%, rgba(0, 42, 44, 0.7) 100%)",
      }}
    >
      <div className="text-[14px] font-bold text-[#FFFFFF] mb-[7px] flex items-center">
        <img
          src="/influence-icon.png"
          className="w-[16px] h-[16px] mr-[2px] ml-[5px]"
        />
        Influence
      </div>
      <div className="flex justify-between">
        <InfoBlock
          list={[
            { label: "DirectInvited", value: directInvited || "-" },
            { label: "Value", value: direct_value || "-" },
          ]}
        />
        <InfoBlock
          list={[
            { label: "IndirectInvited", value: indirectInvited || "-" },
            { label: "Value", value: indirect_value || "-" },
          ]}
        />
      </div>
    </div>
  );
}

export default InfluenceInfo;
