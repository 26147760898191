import TimeBarItem from "./TimeBarItem";

function TimeBar(props) {
  const { list } = props;
  const typeList = ["D", "H", "M"];
  return (
    <div className="flex items-center justify-start">
      {list?.map((item, idx) => {
        return (
          <div className="flex items-center" key={idx}>
            <TimeBarItem type={typeList[idx]} time={item} />
            {idx < typeList.length - 1 && (
              <span className="text-[#fff] font-bold inline-flex w-[10px] justify-center items-center">
                :
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default TimeBar;
