import { httpPost } from "../../utils/httpRequest";

/**
 * 获取用户信息
 * @see https://candy-porpoise-601.notion.site/f078ace3c40d48f688ec921949fb39ae
 */
export async function getPikaInfos() {
  try {
    const reqData = await httpPost("/v1/farming/getPikaInfos");
    return reqData;
  } catch (err) {
    return null;
  }
}
