import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import CardBox from "./CardBox";
import { useEffect, useState } from "react";
import TapOreBoxTemplates from "../../../constants/TapOreBoxTemplates";

function SwiperBoxes(props) {
  const { data, onClick, onOpen, isLoading } = props || {};
  const [boxesList, setBoxesList] = useState([]);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const [curIdx, setCurIdx] = useState(0);

  useEffect(() => {
    if (data?.boxs) {
      const list = Object.values(data?.boxs).sort((a, b) => {
        return Number(a.boxId) - Number(b.boxId);
      });
      setCurIdx(
        list.findIndex((item) => {
          return item.status !== "done";
        })
      );
      setBoxesList(list);
    } else {
      const list = Object.values(TapOreBoxTemplates).sort((a, b) => {
        return Number(a.id) - Number(b.id);
      });
      setBoxesList(list);
    }
  }, [data]);

  return (
    <div
      className="text-center text-xl font-bold relative h-full"
      id="game_swiper_container"
    >
      <div className="swiper_prev absolute top-[50%] left-[2%] z-20 translate-y-[-50%]">
        {!isBeginning && (
          <img className="w-[30px] h-[30px]" src="game_main_box_left.png" />
        )}
      </div>
      <div className="swiper_next absolute top-[50%] right-[2%] z-20 translate-y-[-50%]">
        {!isEnd && (
          <img className="w-[30px] h-[30px]" src="game_main_box_right.png" />
        )}
      </div>
      {boxesList?.length > 0 && (
        <Swiper
          slidesPerView={1}
          modules={[Navigation]}
          navigation={{ prevEl: `.swiper_prev`, nextEl: `.swiper_next` }}
          onSlideChange={(data) => {
            if (data?.isBeginning) {
              setIsBeginning(true);
              setIsEnd(false);
            } else if (data?.isEnd) {
              setIsEnd(true);
              setIsBeginning(false);
            } else {
              setCurIdx(data?.activeIndex);
              setIsEnd(false);
              setIsBeginning(false);
            }
          }}
          initialSlide={curIdx}
        >
          {boxesList?.map((item, idx) => {
            return (
              <SwiperSlide key={idx}>
                <CardBox
                  isLoading={isLoading}
                  data={item}
                  oreRemainAmount={Number(data.oreRemainAmount)}
                  no={idx + 1}
                  onClick={onClick}
                  onOpen={onOpen}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
}

export default SwiperBoxes;
