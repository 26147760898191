import { useInterval } from "ahooks";
import { LAST_ACTIVITY_TIIME, RELOAD_TIME_GAP } from "../constants";
import { useEffect } from "react";

function checkReload(refreshTime = false) {
  const nowTime = new Date().getTime();
  const lastActivityTime = Number(localStorage.getItem(LAST_ACTIVITY_TIIME));
  console.log("lastActivityTime:", lastActivityTime);
  if (refreshTime) {
    localStorage.setItem(LAST_ACTIVITY_TIIME, `${nowTime}`);
  }
  if (nowTime && nowTime - lastActivityTime >= RELOAD_TIME_GAP) {
    window.location.reload();
  }
}

function usePageReload() {
  localStorage.getItem(LAST_ACTIVITY_TIIME);

  useInterval(() => {
    checkReload();
  }, 1000 * 60);

  useEffect(() => {
    function onDocClick() {
      checkReload(true);
    }
    function onVisibilityChange() {
      if (document.visibilityState === "visible") {
        checkReload();
      }
    }

    const nowTime = new Date().getTime();
    localStorage.setItem(LAST_ACTIVITY_TIIME, `${nowTime}`);
    console.log("初始化pika活跃时间");

    document.addEventListener("click", onDocClick);
    document.addEventListener("visibilitychange", onVisibilityChange);
  }, []);
}

export default usePageReload;
