export default function TaskSvg() {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.01566 0.000244141H8.98434C10.0961 0.000244141 11 0.904702 11 2.0159V6.92617H8.63704C7.69338 6.92617 6.92593 7.69362 6.92593 8.63728V11.0002H2.01566C0.903936 11.0002 0 10.0958 0 8.98459V2.0159C0 0.90418 0.904457 0.000244141 2.01566 0.000244141ZM5.50006 7.09161L7.53554 5.05614C7.81664 4.77502 7.81669 4.31969 7.53558 4.03858C7.25446 3.75748 6.79918 3.75748 6.51807 4.03858L4.99131 5.56535L4.48256 5.05661C4.20145 4.7755 3.74617 4.77549 3.46506 5.05661C3.18396 5.33772 3.18396 5.793 3.46506 6.07411L4.48256 7.09161C4.76368 7.37271 5.21896 7.37271 5.50006 7.09161ZM7.94441 10.9996V8.85118V8.85066C7.94441 8.35101 8.3514 7.94409 8.85113 7.94409H11L7.94441 10.9996Z" fill="url(#paint0_linear_1_259)"/>
      <defs>
      <linearGradient id="paint0_linear_1_259" x1="0" y1="5.50024" x2="11" y2="5.50024" gradientUnits="userSpaceOnUse">
      <stop stopColor="#16FFF7"/>
      <stop offset="1" stopColor="#FFFBE8"/>
      </linearGradient>
      </defs>
    </svg>
  )
}