import { BOX_STATUS_DONE } from "../constance";

const w = 13;
const bgStrokeColor = "#FFFFFF66"; //"#8CFFF8";
const valStrokeColor = "#EEFDCB";

function CircleProgress(props) {
  const { value = 0.4, r, top, onClick, status } = props;

  const circleLen = 2 * Math.PI * r;
  const barVal = circleLen * value;
  const cV = r + 2 * w;
  return (
    <svg
      className={`absolute ${status !== BOX_STATUS_DONE && "cursor-pointer"}`}
      style={{ left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}
      width={cV * 2}
      height={cV * 2}
      viewBox={`0 0 ${cV * 2} ${cV * 2}`}
      onClick={onClick}
    >
      <circle
        cx={cV}
        cy={cV}
        r={r}
        fillOpacity="0"
        strokeWidth={w}
        stroke={bgStrokeColor}
      />
      {/* <defs>
        <linearGradient id="myGradient">
          <stop offset="0%" stopColor="#8CFFF8" />
          <stop offset="100%" stopColor="#8CFFF8" />
        </linearGradient>
      </defs> */}
      {value > 0 && (
        <circle
          cx={cV}
          cy={cV}
          r={r}
          fillOpacity="0"
          strokeWidth={w}
          stroke="#8CFFF8"
          strokeDasharray={`${barVal} ${circleLen}`}
          strokeLinecap="round"
          transform={`rotate(-90 ${cV} ${cV})`}
          style={{ transition: "stroke-dasharray 0.15s" }}
        />
      )}
    </svg>
  );
}

export default CircleProgress;
