function Title() {
  return (
    <div id="top" className="flex items-center">
      <img
        alt="Invite Friends"
        loading="lazy"
        width="20"
        height="20"
        decoding="async"
        data-nimg="1"
        className="mr-2"
        srcSet="/invite-friend.svg 1x, /invite-friend.svg 2x"
        src="/invite-friend.svg"
        style={{ color: "transparent" }}
      />
      <p className="text-sm font-bold text-[#766FFA]">Invite Friends</p>
    </div>
  );
}

export default Title;
