export function setHttpToken(token) {
  if (!!token) {
    localStorage.setItem("accessToken", token);
  } else {
    localStorage.removeItem("accessToken");
  }
}

export function getHttpToken() {
  return localStorage.getItem("accessToken");
}
