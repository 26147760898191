import Big from "big.js";
/**
 * 带精度的数字四则运算
 * @param num1 数字 1
 * @param sign 运算符号
 * @param num2 数字 2
 * @returns 返回运算结果
 */
export function numCalc(num1, sign, num2, toFixed = 2) {
  const num = new Big(num1);
  let result;
  if (sign === "+") {
    result = num.plus(num2);
  } else if (sign === "-") {
    result = num.minus(num2);
  } else if (sign === "/") {
    result = num.div(num2);
  } else if (sign === "*") {
    result = num.times(num2);
  }
  return Number(result.toFixed(toFixed));
}
