export default function Title({ title }) {
  return (
    <div
      className="text-[27px] font-semibold text-center"
      style={{
        background: "linear-gradient(90deg, #00FFF0 0%, #FCF4CA 48%, #40E596 100%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        textFillColor: "transparent",
        WebkitTextFillColor: "transparent"
      }}
    >
      { title }
    </div>
  )
}