import { useTranslation } from "react-i18next";

export default function MyReward(props) {
  const { t } = useTranslation();
  const { data } = props;
  const { $Pika, invitedAmount, season$USDT, seasonInviteAmount } = data || {};
  return (
    <div
      className="text-white bg-[#000000B2] p-3 rounded-[15px] mt-3"
      style={{
        boxShadow: "0px 0px 20px rgba(0, 255, 255, 0.22)",
      }}
    >
      <div className="flex items-center">
        <img className="w-[9px] mr-2" src="/reward.png" alt="reward" />
        <span className="font-bold text-sm">{t("pika.My Reward")}</span>
      </div>
      <ul className="flex items-center justify-between mt-2 text-[13px]">
        <li className="bg-[#37373780] p-[10px] rounded-[10px] w-[50%] mr-3">
          <div className="text-[13px] font-bold mb-1">{t("pika.Total")}</div>
          <div className="flex items-center">
            <div className="w-[50%]">
              <div className="text-[#FFFFFFCC]">{t("pika.Invited")}</div>
              <div className="bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-transparent bg-clip-text font-bold text-[17px]">
                {invitedAmount || 0}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-[#FFFFFFCC]">Pika</div>
              <div className="bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-transparent bg-clip-text font-bold text-[17px]">
                ${$Pika || 0}
              </div>
            </div>
          </div>
        </li>
        <li className="bg-[#37373780] p-[10px] rounded-[10px] w-[50%]">
          <div className="text-[13px] font-bold mb-1">{t("pika.Season")}</div>
          <div className="flex items-center">
            <div className="w-[50%]">
              <div>{t("pika.Invited")}</div>
              {/* <div className="bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-transparent bg-clip-text font-bold text-[17px]">{seasonInviteAmount || 0}</div> */}
              <div className="bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-transparent bg-clip-text font-bold text-[17px]">
                -
              </div>
            </div>
            <div className="flex-1">
              <div>USDT</div>
              {/* <div className="bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-transparent bg-clip-text font-bold text-[17px]">${season$USDT || 0}</div> */}
              <div className="bg-gradient-to-r from-[#8BFFF8] to-[#EFFCCA] text-transparent bg-clip-text font-bold text-[17px]">
                -
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
