import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend'
import LocalStorageBackend  from 'i18next-localstorage-backend'
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { APP_VERSION } from "./version";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // lng: 'en',
    debug: true,
    backend: {
      backends: [ LocalStorageBackend, HttpApi ],
      backendOptions: [
        {
          prefix: 'i18next_res_',
          expirationTime: 7 * 24 * 60 * 60 * 1000
        },
        {
          loadPath: `https://assets.crystalfun.io/frontend/text/locales/{{lng}}/{{ns}}.json?v=${APP_VERSION}`
        }
      ]
      // loadPath: 'https://assets.outer.gg/frontend/text/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
